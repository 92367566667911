import { Component, OnInit } from '@angular/core';
import { ReleveDesNotesService } from './releve-des-notes.service';
import * as decode from 'jwt-decode';
import { FormGroup, Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { LoginService } from "./../../login/login/login.service";
import { MatiereService } from 'src/app/layout/gestion-matieres/matiereservice';

@Component({
  selector: 'app-releve-des-notes', 
  templateUrl: './releve-des-notes.component.html',
  styleUrls: ['./releve-des-notes.component.scss'],
  providers:[MatiereService]
})
export class ReleveDesNotesComponent implements OnInit {
  listNotesEleve: any;
  username: any;
  matiereEleve = "Tous les matières";
  userData: any;
  coefficient: any;
  //MatTrimestreForm: FormGroup;
  Ischoise: boolean;
  ClassEleve: boolean;
  coefficientBoeal: false;
  matiereChosed = false;
  listNotesEleveFiltByMati: any;
  nomPrenomEleve: any;
  matieresList: any[];

  constructor(private formBuilder: FormBuilder, private releveDesNotesService: ReleveDesNotesService,
    private matiereservice: MatiereService,private loginService: LoginService) {
   // this.MatTrimestreForm = this.formBuilder.group({
     // 'nomTrimestre': ['', Validators.required],
   //  'matiere': ['', Validators.required]
  //  });
  }

  ngOnInit() {
    this.getNotesEleve();
    this.getUserbyusername();
    this.getMatieres();
  }

  getUserbyusername() {
    var decoded = decode(localStorage.getItem('token'));
    this.username = decoded.sub;
    this.loginService.getUserbyusername(this.username)
      .subscribe((userData) => {
        this.userData = userData;
        this.ClassEleve = this.userData.classeActuel;
        this.nomPrenomEleve = this.userData.prenom + " " + this.userData.nom;
      }, error => {
        console.log(error);
      });
  }
  
  

  getNotesEleve() {
    if (localStorage.getItem('token') != null) {
      var decoded = decode(localStorage.getItem('token'));
      this.username = decoded.sub;
    }

    /*  this.releveDesNotesService.getNotesEleve(this.username).subscribe((dataEleve) => {
        this.listNotesEleve = dataEleve;
        this.matiere = this.listNotesEleve[0].matiere.nommatiereFR;
        this.coefficient = this.listNotesEleve[0].coefficient.valeur;
        this.coefficientBoeal = false;
        console.log(this.listNotesEleve);
      }, error => {
        console.log(error);
      }); */
  }
  getMatieres() {

    this.matiereservice.getAllMatieres()
      .subscribe((matieres) => {
        this.matieresList = matieres;
        console.log(this.matieresList);
      }, error => {
        console.log(error);
      });
  }

  sendChoiseMatTrimestre(Ischoise, nomTrimestreChosed, matiere) {
    this.Ischoise = Ischoise;
    this.releveDesNotesService.getNotesEleve(this.username, nomTrimestreChosed, this.ClassEleve).subscribe((dataEleve) => {
      this.listNotesEleve = dataEleve;
      if (matiere == "all") {

        let EPtest = this.listNotesEleve.filter(function (eleve) {
          return eleve.epreuve.type == "test";
        });

        let EPOral = this.listNotesEleve.filter(function (eleve) {
          return eleve.epreuve.type == "oral";
        });

        let EPTP = this.listNotesEleve.filter(function (eleve) {
          return eleve.epreuve.type == "TP";
        });
        let EPDControle = this.listNotesEleve.filter(function (eleve) {
          return eleve.epreuve.type == "devoir_controle";
        });
        let EPODSynthese = this.listNotesEleve.filter(function (eleve) {
          return eleve.epreuve.type == "devoir_synthese";
        });


        console.log(this.listNotesEleve);
        //console.log(EPtest);
        //console.log(EPOral);


      } else {
        this.matiereChosed = true;
        let listNotesEleveFiltredByMatiere = this.listNotesEleve.filter(function (eleve) {
          return eleve.matiere.nommatiereFR == matiere;
        });
        console.log(listNotesEleveFiltredByMatiere);

        let EPtest = this.listNotesEleve.filter(function (eleve) {
          return eleve.epreuve.type == "test";
        });
        if (EPtest.length == 0) {
          EPtest.push({ valeur: "" });
        }

        let EPOral = this.listNotesEleve.filter(function (eleve) {
          return eleve.epreuve.type == "oral";
        });
        if (EPOral.length == 0) {
          EPOral.push({ valeur: "" });
        }

        let EPTP = this.listNotesEleve.filter(function (eleve) {
          return eleve.epreuve.type == "TP";
        });
        if (EPTP.length == 0) {
          EPTP.push({ valeur: "" });
        }

        let EPDControle = this.listNotesEleve.filter(function (eleve) {
          return eleve.epreuve.type == "devoir_controle";
        });
        if (EPDControle.length == 0) {
          EPDControle.push({ valeur: "" });
        }

        let EPODSynthese = this.listNotesEleve.filter(function (eleve) {
          return eleve.epreuve.type == "devoir_synthese";
        });
        if (EPODSynthese.length == 0) {
          EPODSynthese.push({ valeur: "" });
        }


        let NewEleve = [];
        let i = 0;
        NewEleve.push({ matiere: matiere, Notetest: +EPtest[i].valeur, NoteOral: EPOral[i].valeur, NoteTP: EPTP[i].valeur, NoteControle: EPDControle[i].valeur, NoteSynthese: EPODSynthese[i].valeur });

        console.log(NewEleve);
        this.listNotesEleveFiltByMati = NewEleve;

      }


    }, error => {
      console.log(error);
    });







  }




}
