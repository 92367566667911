import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from 'src/app/model/user';
//import { CONFIG } from 'src/app/config';
import { CONFIG } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Http, Response, Headers, RequestOptions } from '@angular/http';

import * as decode from 'jwt-decode';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(public router: Router, private http: Http) { }

    canActivate() {
        if (localStorage.getItem('token') != null) {

            return true;
        }
        else {
            this.router.navigate(['./login']);
            return false;
        }
    }

}
