import { Router, RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { EspaceEleveComponent } from "./espace-eleve.component";
import { CantineEleveComponent } from './cantine-eleve/cantine-eleve.component';
import { ReleveDesNotesComponent } from './releve-des-notes/releve-des-notes.component';
import { AuthGuard } from "../shared/guard/auth.guard";
const routes: Routes = [
    {
        path: '', component: EspaceEleveComponent,
        canActivate: [AuthGuard],
        children: [
            // { path: '', loadChildren: './eleve-parrain/eleve-parrain.module#EleveParrainModule' },
            { path: '', loadChildren: './dashboard-eleve/dashboard-eleve.module#DashboardEleveModule' },
            { path: 'travail-demande-eleve', loadChildren: './travail-demande-eleve/travail-demande-eleve.module#TravailDemandeEleveModule' },
            { path: 'modifier-eleve', loadChildren: './modifier-eleve/modifier-eleve.module#ModifierEleveModule' },
            { path: 'cantines-eleve', component: CantineEleveComponent },
            { path: 'releve-notes-eleve', component: ReleveDesNotesComponent },
            { path: 'document-partger', loadChildren: './document-partger/document-partger.module#DocumentPartgerModule' },
        ]
    }

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class EspaceEleveRoutingModule {

}

