import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import * as decode from 'jwt-decode';
import { LoginService } from 'src/app/login/login/login.service';
import { SaisieDesNotesService } from './../saisie-des-notes/saisie-des-notes.service';
import { ReleveDesNotesEnsService } from '../releve-des-notes-ens/releve-des-notes-ens.service';
import { FormuleNoteService } from '../formule-note/formule-note.service';
@Component({
  selector: 'app-releve-moyennes-enseignant',
  templateUrl: './releve-moyennes-enseignant.component.html',
  styleUrls: ['./releve-moyennes-enseignant.component.scss']
})
export class ReleveMoyennesEnseignantComponent implements OnInit {

  classformaddMoyMat: FormGroup;
  epvreuveForm: FormGroup;
  user: any;
  AllClasses: any;
  Ischoise: any;
  username: any;
  classes: any[];
  sortedClasse: any[];
  sendedEpeuve: any;
  nomTrimestre: any;
  classeShosed: any;
  listNoteEleve: any[] = [];
  listMoyeneMatiereEleve: any[]=[];
  matiere: any;
  IsChoise = false;
  listleve: any;
j:0;
  classeChosed: any;
  allEleves: any[] = [];
  nomTrimestreChosed: any;
  listNoteEleveNew: any;
  moyenneAllEleve: any;
  annescolaire:any;
  allepreuve:any [];
  loading :boolean =false ;
  notesByClasseAndEpreuve:any [];
  epreuves: any[];
  classByTeacher:any[];
  matiereByProf: any;
  selectedClasse:any;
  epreuve:any
  constructor(
    private formBuilder: FormBuilder,
    private saisieDesNotesService: SaisieDesNotesService,
    private loginService: LoginService,
    private releveDesNotesEnsService: ReleveDesNotesEnsService,
    private formuleNoteService: FormuleNoteService,
    public router: Router,
    
  ) {
    this.classformaddMoyMat = this.formBuilder.group({
      'classe': ['', Validators.required],
      'nomTrimestre': ['', Validators.required],
      
    });
  }


  ngOnInit() {
    this.getUserbyusername();
    this.getAllClassesBySelectedProf();
    
    this.getAllClasses();
    this.getbyusername();
    
  }getUserbyusername() {
    if (localStorage.getItem('token') != null) {
      var decoded = decode(localStorage.getItem('token'));
      this.username = decoded.sub;
      this.saisieDesNotesService.getUserbyusername(this.username)
        .subscribe((data) => {
          this.matiereByProf= data.matiere;
           console.log("thiiiiiiiiiiiiiis matiere by prof",this.matiereByProf);
          
        }, error => {
          console.log(error);
        });
    }
  }
  getbyusername(){
    this.loading=true ;
    var decoded = decode(localStorage.getItem("token"));
    this.username = decoded.sub;
    this.loginService.getUserbyusername(this.username).subscribe((res=>
      {
        this.user = res;
                  if (this.user.profil == "Parrain") {
                    this.router.navigate(["/espaceParent"]);
                  } else if (this.user.profil == "Directeur") {
                    this.router.navigate(["DashboardDirecteur"]);
                  }
      }))
      console.log(this.user)
      this.loading=false;
  }
  getannescolaire(){
  this.releveDesNotesEnsService.getannescolaire().subscribe(data=>{
  this.annescolaire = data ;
  console.log("**************/////////////******* " + this.annescolaire)
  }, error => {
    console.log(error);
  });

  }
 

  getAllClassesBySelectedProf() {
    if (localStorage.getItem('token') != null) {
      var decoded = decode(localStorage.getItem('token'));
      this.username = decoded.sub;
      let ens = this.username.includes('ens');
      console.log(ens);
      this.formuleNoteService.findClassesByTeacher(this.username).subscribe(
        (data) => {
          this.classByTeacher = data.sort(function(a,b){
           return a.id - b.id;
          })
          console.log("class by teacher ", this.classByTeacher);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }
  getAllClasses() {
    this.formuleNoteService.getAllClasses().subscribe(
      (data) => {
        this.AllClasses = data;
        console.log('getAllClasse');
        console.log(this.AllClasses);    
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllElevesbySelectedClassHAHA(classe) {
    console.log(classe);
    
    this.listNoteEleve!.length = 0;
    this.releveDesNotesEnsService.getAllElevesbySelectedClass(classe)
      .subscribe((data) => {
        
        this.allEleves = data;       
        console.log("eleves par classe", this.allEleves);
     
      },
      error => {
        console.log("error eleve by classe ",error);
        
      });
  }  

//Moyenne Matiere relevé

afficherReleveMoyenneMatiere(classe, trimestre){
    console.log(classe, trimestre )
  const selectedClasse = this.classByTeacher.find(cls=>cls.nomclasse == classe);
  this.listMoyeneMatiereEleve.length = 0;
  
  this.formuleNoteService.getAllElevesbySelectedClass(classe)
    .subscribe((data) => {
    this.allEleves = data;           
    console.log("eleves par classe", this.allEleves);
    this.allEleves.map((elv)=>(
      
      this.formuleNoteService.findAllMoyenneMatiereByEleveClasseTrimestreMatiere(elv.id, selectedClasse.id, trimestre, this.matiereByProf.idmatiere).subscribe((data)=>{
        console.log("data",data);
        
        if(data.length>0){
          let sortedData = data.sort(function (a, b) {
            const nameA = a.eleve.nom.toUpperCase(); 
            const nameB = b.eleve.nom.toUpperCase();
            
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
          
            // names must be equal
            return 0;
          })  
          this.IsChoise=true;
          this.listMoyeneMatiereEleve.push(sortedData);
        }
        console.log("moyenne by eleve ", this.listMoyeneMatiereEleve);
      })
    ))
  
    },
    error => {
      console.log("error eleve by classe ",error);
      
    });

}
}
