import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-parrain',
  templateUrl: './dashboard-parrain.component.html',
  styleUrls: ['./dashboard-parrain.component.scss']
})
export class DashboardParrainComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
