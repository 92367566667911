import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import * as decode from 'jwt-decode';
import { CONFIG } from "../../../environments/environment";
import { map } from 'rxjs/operators';
import { Http, Response, Headers, RequestOptions, } from '@angular/http';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { User } from '../../model/user';
import { Notebynow } from 'src/app/shared/topnavbar/topnavbar.component';
import { Enseignant } from 'src/app/model/enseignant';
import { imagetable } from 'src/app/model/imagetable';
import { EleveParrain } from 'src/app/espace-parent/service/eleve-parrain';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable()
export class LoginService {
  private base: string;
  private subject = new Subject<any>();
  public token: string;

  public username;
  public profile;
  public user: any[];
  public Anneescolaire: any[];
  passwordToken;
  name;
  

  
  constructor(public router: Router, private http: Http, private _http: Http, private __http: HttpClient) {

    this.base = CONFIG.URL + 'auth/login';
  }



  loggedIn() {
    return (localStorage.getItem("token") === null) ? false : true;
   
  }

  getLoggedIn() {
    return this.subject.asObservable();
  }

  createAuthorizationHeader(headers: Headers) {
    var token = localStorage.getItem('token');
    headers.append('Authorization', 'Bearer ' + token);
  }

  Updatemp(password: string, email: string): Observable<any[]> {
    this.passwordToken=localStorage.getItem('passwordtoken');
    let url = CONFIG.URL + CONFIG.apiPath + 'enseignants/resetmp' + '?email=' + email+'?token='+this.passwordToken;
    let headers = new Headers({ 'Content-Type': 'application/json' });
    this.createAuthorizationHeader(headers);
    
console.log("///"+this.http.put(url, JSON.stringify({
  password: password, email: email} )))
    return this.http.put(url, JSON.stringify({
      password: password, email: email
    } || null)
      , { headers: headers })
      .map(response => response.json()).catch(this.handleError)
      ;
  }
  editUser(u: Enseignant): Observable<any[]> {
    let url = CONFIG.URL + CONFIG.apiPath + 'user/update';

    let headers = new Headers({ 'Content-Type': 'application/json' });

    this.createAuthorizationHeader(headers);

    return this._http.put(url, u,  { headers: headers })
      .map(res => res.json())
      .catch(this.handleError);
  }

  url4: string = CONFIG.URL + CONFIG.apiPath + 'user/update';

  editusers(email: any, phone_number: any, username: any, password: any): Observable<any[]> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    this.createAuthorizationHeader(headers);

    return this._http.put(this.url4, JSON.stringify({
      email: email, phone_number: phone_number, username: username, password: password
    } || null), { headers: headers })
      .map(response => response.json()).catch(this.handleError);
  }





  Restlogin(email: any): Observable<any[]> {

    console.log(email);
    let url = CONFIG.URL + 'renitialisation/resetPasswordtest?userEmail=:email';
    console.log(url);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    return this.http.post( CONFIG.URL + "renitialisation/resetPasswordtest?userEmail="+email,email)
      .map(response => response.json()).catch(this.handleError)
      ;
  }





  getUserbyid(id: any): Observable<any> {

    let url1 = CONFIG.URL + 'api/allbyetab/getbyid';

    return this.http.get(url1, JSON.stringify({ id: id }))

      .pipe(map(res => {
        const u = res.json();
      }));
  }
  getByUsername(username: any): Observable<any> {

    let url1 = CONFIG.URL + 'api/user/getbyusername';

    return this.http.get(url1, JSON.stringify({ username: username }))

      .pipe(map(res => {
        const u = res.json();
      }));



  }




  getNotifnote(): Observable<Notebynow[]> {

    let urlnottif = CONFIG.URL + 'api/note/allnotesbynow';
    //  let url = CONFIG.URL + CONFIG.apiPath + 'matiere/all'+'?username='+username;
    let headers = new Headers();
    this.createAuthorizationHeader(headers);

    return this.http.get(urlnottif, { headers: headers })
      .map(res => res.json())
      .catch(this.handleError);
  }


  doLogin(credentials) {
    return this.http.post(this.base, credentials).pipe(
      map(res => {
        const data = res.json();
        if (data) {
          // localStorage.setItem('token', data.token);
          localStorage.setItem('user', JSON.stringify(data.user));
        }
      }));
  }


  login(username: string, password: string): Observable<any> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    let url = CONFIG.URL + 'auth/login';
    return this.http.post(url, JSON.stringify({ username: username, password: password } || null), options)
      .pipe(map((response: Response) => {
        if ((response.status === 200) || (response.status === 201)) {
          const data = response.json();
          localStorage.setItem('token', data.token);
          this.user = data;
          localStorage.setItem('user', data.profil);
         
          
          let token = response.json().access_token;
          this.token = token;
          let profile = response.json().profil;
          this.profile = profile;
          localStorage.setItem('token', token);
          this.getByUsername(username);
          localStorage.setItem('userName', username);
          console.log(username);
          
          return true;
        }

      })
        , catchError(error => {
          if (error.status === 500) {
            return Observable.throw(new Error(error.status));
          } else if (error.status === 400) {
            return Observable.throw(new Error(error.status));
          } else if (error.status === 409) {
            return Observable.throw(new Error(error.status));
          } else if (error.status === 406) {

          } else {
            return Observable.throw(new Error(error.status));

          }
        })
      )
  }



  private extractData2(res: Response) {
    let body = res.json();
    return body.access_token || {};
  }


  getRoles() {
    const user: User = JSON.parse(localStorage.getItem('user'));

    return user.profil;
  }
  logout(): void {
    this.token = null;
    localStorage.removeItem('token');
    window.location.reload();
  }
  url6: string;
  getUserbyusername(username: string): Observable<User> {
    let headers = new Headers();
    this.createAuthorizationHeader(headers);
    this.url6 = CONFIG.URL + CONFIG.apiPath + 'user/getbyusername' + '?username=' + username;
    let a: any;
    return a = this.http.get(this.url6, { headers: headers })
      .pipe(map(res => res.json()))
      ;

  }

  getAllimage(): Observable<imagetable> {
    var decoded = decode(localStorage.getItem('token'));
    let username = decoded.sub;
    let url65 = CONFIG.URL + 'image/imagebyUsername' + '?username=' + username;
    let headers = new Headers();
    this.createAuthorizationHeader(headers);
    return this._http.get(url65, { headers: headers })
      .map(res => res.json())
      .catch(this.handleError);
  }


  getAllimageSign(): Observable<imagetable> {
    var decoded = decode(localStorage.getItem('token'));
    let username = decoded.sub;
    let url65 = CONFIG.URL + 'signature/signaturebyUsername' + '?username=' + username;
    let headers = new Headers();
    this.createAuthorizationHeader(headers);
    return this._http.get(url65, { headers: headers })
      .map(res => res.json())
      .catch(this.handleError);
  }



  getAllbyparent(): Observable<EleveParrain[]> {
    var decoded = decode(localStorage.getItem('token'));
    let username = decoded.sub;
    let url6500 = CONFIG.URL +CONFIG.apiPath+ 'eleve/allbyParrain' + '?username=' + username;
    let headers = new Headers();
    this.createAuthorizationHeader(headers);
    return this._http.get(url6500, { headers: headers })
      .map(res => res.json())
      .catch(this.handleError);
  }


  getAllimageSignProf(profName): Observable<imagetable> {
    var decoded = decode(localStorage.getItem('token'));
    let username = decoded.sub;
    let url65 = CONFIG.URL + 'signature/signaturebyUsername' + '?username=' + profName;
    let headers = new Headers();
    this.createAuthorizationHeader(headers);
    return this._http.get(url65, { headers: headers })
      .map(res => res.json())
      .catch(this.handleError);

  }

  url10: string = CONFIG.URL + CONFIG.apiPath + 'annee/getanneeactuelle';



  private handleError(error: Response | any) {
    // In a real world app, you might use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      const err = body.error || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return Observable.throw(errMsg);
  }

  resetPassword(email: string, newPassword: string): Observable<any> {
    return this.http.put(
      CONFIG.URL +CONFIG.apiPath+ 'user/newPassword/' + email + '/' + newPassword,
      httpOptions
    );
  }
  createanAuthorizationHeader(headers: HttpHeaders) {
    const token = localStorage.getItem('token');
    headers= new HttpHeaders
    headers.append('Authorization', 'Bearer ' +token); 
  }
  updatePassword(password:string, newPassword:string): Observable<any>{
    const name=localStorage.getItem('userName');
    const decoded = decode(localStorage.getItem('token'));
    const username = decoded.sub;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
      console.log(headers);
    return this.http.post(
      CONFIG.URL+CONFIG.apiPath+'user/change-password'+'?name='+ name +'&newPassword='+ newPassword +'&password='+password,{ headers: headers }
    );
    

  }

}
