import { Component, OnInit } from '@angular/core';
import { EspaceParrainService } from '../../service/espace-parrain.service';
import { ActivatedRoute } from '@angular/router';
import { ElevePenalite } from '../../service/eleve-penalite';
import { Location } from '@angular/common';
import { MatTableDataSource } from '@angular/material';
import { routerTransition } from 'src/app/router.animations';

@Component({
  selector: 'app-list-penalite',
  templateUrl: './list-penalite.component.html',
  styleUrls: ['./list-penalite.component.scss'],
  animations: [routerTransition()],

})
export class ListPenaliteComponent implements OnInit {

  public numIns: string;
  public listPenalite: ElevePenalite[];
  public displayedColumns: string[] = ['datepenalite', 'motif', 'matiere', 'nomClasse', 'remarque'];

  public dataSource = new MatTableDataSource<ElevePenalite>();
  newTab = [];
  elevName: any;
  constructor(private elevePanaliteService: EspaceParrainService,
    private activateRoute: ActivatedRoute,
    private location: Location) { }

  ngOnInit() {
    this.numIns = this.activateRoute.snapshot.paramMap.get('id');
    this.getListPenaliteEleveByNumins();



    // console.log(this.dataSource);
  }





  getListPenaliteEleveByNumins() {
    function parseThisDate(date) {
      let dateParts = date.value.split(" , ");
      return new Date(dateParts[0]);
    }

    function parseheur(date) {
      let dateParts = date.split(" , ");
      return (dateParts[1]);
    }

    this.elevePanaliteService.getListPenaliteEleveByNuminscr(this.numIns)
      .subscribe(res => {
        if (res.length == 0) {
          console.log('lenght 0');
          this.listPenalite = [{ prenomEleve: '', motif: '', nomClasse: '', date_Absence: '', matiere: '', type: '', remarque: '' }];
          this.elevName = "";
        } else {
          console.log('lenght 10');

          this.listPenalite = res;
          let newTab2 = [];
          this.listPenalite.forEach(function (ithem) {
            console.log(ithem.prenomEleve);
            newTab2.push({ prenomEleve: ithem.prenomEleve, motif: ithem.motif, nomClasse: ithem.nomClasse, dateAbsence: ithem.date_Absence, heurAbs: ithem.date_Absence, matiere: ithem.matiere, type: ithem.type, remarque: ithem.remarque })
          });

          // this.listPenalite = newTab2;
          this.elevName = this.listPenalite[0].prenomEleve;
          this.listPenalite = newTab2.slice().sort((a, b) => Date.parse(b.dateAbsence) - Date.parse(a.dateAbsence));

        }


        console.log(res);
        console.log(this.listPenalite);

        this.dataSource.data = this.listPenalite;
      }
      );
  }




  clickGoBack() {
    this.location.back();
  }
}
