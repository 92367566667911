import { Router, RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { EspaceParentComponent } from "./espace-parent.component";
import { AuthGuard } from "../shared/guard/auth.guard";
import { DetailComponent } from './travail-demande/detail/detail.component';
import { CantinePrentComponent } from './cantine-prent/cantine-prent.component';
import { EditProfilComponent } from './edit-profil/edit-profil.component';
import { RessourcesElevesComponent } from "./ressources-eleves/ressources-eleves.component";
import { RessourcesDetailComponent } from "./ressources-eleves/ressources-detail/ressources-detail.component";
const routes: Routes = [
    // {path: '', redirectTo:'/eleve-parrain',pathMatch: 'full'}
    {
        path: '', component: EspaceParentComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', loadChildren: './eleve-parrain/eleve-parrain.module#EleveParrainModule' },
            { path: 'travail-demande', loadChildren: './travail-demande/travail-demande.module#TravailDemandeModule' },
            { path: 'travail-demande/detail', component: DetailComponent },
            { path: 'ressources-eleves', component: RessourcesElevesComponent, loadChildren: './ressources-eleves/ressources-eleves.module#RessourcesElevesModule' },
            { path: 'ressources-eleves/ressources-detail', component: RessourcesDetailComponent },

            { path: 'cantines-parent', component: CantinePrentComponent },
            { path: 'edit-parent', component: EditProfilComponent },
            { path: 'gestiondesnotes', loadChildren: './gestiondesnotes/gestiondesnotes.module#GestiondesnotesModule' },


        ]
    }

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class EspaceParrainRoutingModule {

}
