import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
// import { Utilisateur } from './utilisateur';
// import {CONFIG} from "../../config";
import { CONFIG } from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable()
export class SitesService {


    // We need Http to talk to a remote server.
    constructor(private _http: HttpClient) {
    }




    // Get all salariers
    url: string = CONFIG.URL + CONFIG.apiPath + 'site/getall';

    // Add  user

    url2: string = CONFIG.URL + CONFIG.apiPath + 'user/sites/notused?username=';

    // update  site
    url4: string = CONFIG.URL + CONFIG.apiPath + 'user/sites/update';

    // delete  user's sites
    url5: string = CONFIG.URL + CONFIG.apiPath + 'site/remove';

    getSites(): Observable<any[]> {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this._http.get<any>(this.url, { headers: headers });
    }

    getUsersOutSites(id: string): Observable<any[]> {
        // let params = new HttpParams().set("id",id);
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this._http.get<any>(this.url2 + id, { headers: headers/*, params: params*/ })
            ;
    }

    updateSiteUsers(username: string, newSites: any[]): Observable<any[]> {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this._http.post<any>(this.url4, ({
            username: username,
            sites: newSites
        } || null), { headers: headers })
            ;
    }

    deleteUser(username: string[]): Observable<any[]> {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this._http.post<any>(this.url5, username, { headers: headers })
            ;
    }


}
