import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { CONFIG } from '../../../environments/environment';
import * as decode from 'jwt-decode';
import { AccessUrl } from './role';
import { Role, Access } from './gestion-roles.component';
import { User } from '../../model/user';
import { Roles } from './roles';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable()
export class RolesService {

    constructor(private _http: HttpClient, private http: HttpClient) {

    }

    urls: any = {
        getAll: 'role/getall',
        getById: 'role/getbyid',
        add: 'role/add',
        update: 'role/update',
        delete: 'role/remove',
        getGestion: 'role/getgestion',
        updateGestion: 'role/updategestion',

    };


    // Get all salariers
    url: string = CONFIG.URL + CONFIG.apiPath + 'role/getall';

    url6: string = CONFIG.URL + CONFIG.apiPath + 'role/getbyid';

    url60: string = CONFIG.URL + CONFIG.apiPath + 'role/getbynom';


    // Add  user
    url2: string = CONFIG.URL + CONFIG.apiPath + 'role/add';

    




    // update  user
    url4: string = CONFIG.URL + CONFIG.apiPath + 'role/update';



    // delete  user
    url5: string = CONFIG.URL + CONFIG.apiPath + 'role/remove';

    deleteRole(arr: any[]): Observable<any[]> {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        const url6 = CONFIG.URL + CONFIG.apiPath + 'role/remove';
        return this._http.post<any>(url6, arr, { headers: headers });
    }
    DeleteRoles(id: any): any {
        const decoded = decode(localStorage.getItem('token'));
        const username = decoded.sub;
        const url = CONFIG.URL + CONFIG.apiPath + 'role/deleteroles' + '?id=' + id;
        return this._http.delete(url, id);
    }
    getGestion(id: any): Observable<any> {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        const url6 = CONFIG.URL + CONFIG.apiPath + 'role/getgestion' + '?id=' + id;
        return this._http.get(url6, { headers: headers });
    }

    updateGestion(obj: AccessUrl): Observable<any[]> {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        const url6 = CONFIG.URL + CONFIG.apiPath + 'role/updategestion';
        return this._http.post<any>(url6, obj, { headers: headers });
    }







    getAllAuthorities(): Observable<any[]> {
        const decoded = decode(localStorage.getItem('token'));
        const username = decoded.sub;
        const url = CONFIG.URL + CONFIG.apiPath + 'role/getallbyetablissement' + '?username=' + username;
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this._http.get<any>(url, { headers: headers });
    }
    getAAuthoritieById(id: number, description: any): Observable<Role> {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        this.url6 = CONFIG.URL + CONFIG.apiPath + 'role/getbyid' + '?id=' + id;
        return this._http.get<Role>(this.url6, { headers: headers });
    }
    getAAuthoritieByDescription(description: String): Observable<Role> {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        this.url60 = CONFIG.URL + CONFIG.apiPath + 'role/getbynom' + '?description=' + description;
        return this._http.get<Role>(this.url60, { headers: headers });
    }
    addAuthority(role: Roles): Observable<any[]> {
        const decoded = decode(localStorage.getItem('token'));
        const username = decoded.sub;
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        const url2 = CONFIG.URL + CONFIG.apiPath + 'role/add' + '?username=' + username;
        return this._http.post<any>(url2, ({ role: role } || null), { headers: headers });
    }

   

    editAuthority(role: Role): Observable<any[]> {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this._http.post<any>(this.url4, (role || null), { headers: headers })
            ;
    }

    deleteAuthority(username: string[]): Observable<any[]> {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this._http.post<any>(this.url5, username, { headers: headers });
    }




    getUserbyusername(username: string): Observable<User> {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);

      return this.http.get<User>(CONFIG.URL + CONFIG.apiPath + `user/getbyusername?username=${username}`, { headers: headers });

     }
    getAccess(gestion: string, role: string): Observable<Access> {
        const decoded = decode(localStorage.getItem('token'));
        const username = decoded.sub;
        const url1 = CONFIG.URL + 'api/role/getaccess' + '?gestion=' + gestion + '&role=' + role + '&username=' + username;
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this._http.get<Access>(url1, { headers: headers });
    }
}
