import { Component, OnInit, Input } from '@angular/core';
import * as decode from 'jwt-decode';
// import { CONFIG } from 'src/app/config';
import { CONFIG } from '../../../environments/environment';
import { Message } from 'src/app/model/messagesecoket';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { NavbarService } from 'angular-bootstrap-md';
import { Messageparent } from 'src/app/model/messageparent';
import { LoginService } from 'src/app/login/login/login.service';
import { User } from 'src/app/layout/gestion-utilisateurs/user';
import { DomSanitizer } from '@angular/platform-browser';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import swal from 'sweetalert2';
import { Eleve } from './../../layout/gestion-eleves/eleve';
import { Eleveservice } from './../../layout/gestion-eleves/gestion-eleves.service';

@Component({
  selector: 'app-navbar-eleve',
  templateUrl: './navbar-eleve.component.html',
  styleUrls: ['./navbar-eleve.component.scss']
})
export class NavbarEleveComponent implements OnInit {

  constructor(private loginService: LoginService, private sanitizer: DomSanitizer, private httpClient: HttpClient,
    private eleveService: Eleveservice) { }

  username;
  parent: any;
  profil: any;
  nomuser: any;
  prenomuser: any;
  message: any;
  numInsc: any;
  edituserImg: Eleve = new Eleve();
  user: User;
  selectedFile: File;
  thumbnail: any;
  thumbnailTest: any;

  ngOnInit() {
    if (localStorage.getItem('token') != null) {
      const decoded = decode(localStorage.getItem('token'));
      this.username = decoded.sub;
      console.log('usersocket' + this.username);
    }
    this.getUserbyusername();
    this.getProfileImg();
    $(document).ready(() => {
      $('.close-sidebar-btn').click(function () {
        const classToSwitch = $(this).attr('data-class');
        const containerElement = '.app-container';
        $(containerElement).toggleClass(classToSwitch);
        const closeBtn = $(this);
        if (closeBtn.hasClass('is-active')) {
          closeBtn.removeClass('is-active');
        } else {
          closeBtn.addClass('is-active');
        }
      });
    });
  }


  onLoggedout() {
    localStorage.removeItem('token');
  }

  getUserbyusername() {
    const decoded = decode(localStorage.getItem('token'));
    this.username = decoded.sub;
    this.loginService.getUserbyusername(this.username)
      .subscribe((user) => {
        this.user = user;
        this.profil = user.profil;
        this.nomuser = this.user.nom;
        this.prenomuser = this.user.prenom;
      }, error => {
        console.log(error);
      });
  }



  public onFileChanged(event) {
    this.selectedFile = event.target.files[0];
  }
// upload image
  onUpload() {
    // FormData API provides methods and properties to allow us easily prepare form data to be sent with POST HTTP requests.
    const uploadImageData = new FormData();
    uploadImageData.append('imageFile', this.selectedFile, this.selectedFile.name);
    const decoded = decode(localStorage.getItem('token'));
    const username = decoded.sub;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    // Make a call to the Spring Boot Application to save the image
    this.httpClient.post(CONFIG.URL + 'image/uploadimageeleve' +
      '?numinscription=' +
      this.username,
      uploadImageData,
      {
        observe: 'response',
        reportProgress: true,
        headers: headers
      }
    )
      .subscribe((response) => {
        if (response.status === 200) {
          swal({
            position: 'center',
            title: 'La photo est ajoutée avec succès.',
            showConfirmButton: true

          });
          this.getProfileImg();
        } else {
          this.message = 'Image not uploaded successfully';
        }
      }
      );
  }

  getProfileImg() {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    this.eleveService.getImgEleve(this.username).subscribe((imagetable) => {
      const retrievedImage = 'data:image/jpeg;base64,' + imagetable;
      this.thumbnail = this.sanitizer.bypassSecurityTrustUrl(retrievedImage);
    }, error => {
      this.thumbnail = '';
      this.thumbnailTest = true;
    });
  }




}
