import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import * as decode from 'jwt-decode';
import { DatePipe } from '@angular/common';
// import { CONFIG } from 'src/app/config';
import { CONFIG } from '../../../environments/environment';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { Message } from 'src/app/model/messagesecoket';
import { SocketService } from 'src/app/services/socket.service';
import { EspaceprofesseurService } from 'src/app/layout/mon-emplois/espaceprofesseur.service';
import { LoginService } from 'src/app/login/login/login.service';
import { User } from 'src/app/layout/gestion-utilisateurs/user';
import { TopbarService } from './topbar.service';
import { Messagenote } from 'src/app/model/Messagenote';
import { DomSanitizer } from '@angular/platform-browser';
import swal from 'sweetalert2';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Enseignant } from 'src/app/model/enseignant';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-topnavbar',
  templateUrl: './topnavbar.component.html',
  styleUrls: ['./topnavbar.component.scss'],
  providers: [DatePipe, EspaceprofesseurService, LoginService, TopbarService]
})


export class TopnavbarComponent implements OnInit {


  constructor(private httpClient: HttpClient, private toservice: TopbarService,  private db: AngularFirestore,
    private cdr: ChangeDetectorRef, private sanitizer: DomSanitizer,
    private socketService: SocketService, public router: Router, private datePipe: DatePipe, private profservice: EspaceprofesseurService, private loginService: LoginService) {
    this.date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    this.daynowDate = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');

    this.messages = profservice.messages;





  }
  date: string;
  thumbnail: any;
  thumbnailTest: any;
  username = '';
  myDate = new Date();
  exclus = 'exclus';
  retenu = 'retenu';
  appreciation = 'appreciation';
  count = 0;
  countretenu = 0;
  countapreciation = 0;
  user: User;
  profil: string;
  public notifications = 0;
  stompClient2: any;
  stompClient3: any;
  nomuser = '';
  prenomuser = '';
  edituserImg: Enseignant;
  numInsc: string;
  imgSin: any;
  daynowDate: string;
  listNotif: any[];
//  private serverUrl = CONFIG.URLsocket + 'socket'
//  private serverUrl1 = CONFIG.URLsocketnote + 'socketjs'

  isLoaded = true;
  isCustomSocketOpened = false;
  private stompClient;
  messagesretenu: Message[] = [];
  messagesappreciation: Message[] = [];

  messages: Message[] = [];
  messagesnotes: Messagenote[] = [];
  ens: Enseignant = new Enseignant();


  selectedFile: File;
  message: string;








  countnote = 0;
  datasource: Notebynow[];
  datasourcetiming: Notebynow[] = [];

  messagenote: {};

  ngOnInit() {
this.getNotifEleve();
    this.cdr.detectChanges();
    this.getProfileImg();
 //   this.initializeWebSocketConnectionjs();
   // this.initializeWebSocketConnection();

    if (localStorage.getItem('token') != null) {
      const decoded = decode(localStorage.getItem('token'));
      this.username = decoded.sub;
      this.getUserbyusername();
    }

    this.getAllimageSign();

    $(document).ready(() => {

      $('.close-sidebar-btn').click(function () {
        const classToSwitch = $(this).attr('data-class');
        const containerElement = '.app-container';
        $(containerElement).toggleClass(classToSwitch);
        const closeBtn = $(this);
        if (closeBtn.hasClass('is-active')) {
          closeBtn.removeClass('is-active');
        } else {
          closeBtn.addClass('is-active');
        }
      });
    });

  }

  getProfileImg() {

    this.loginService.getAllimage()
      .subscribe((imagetable) => {
        const retrievedImage = 'data:image/jpeg;base64,' + imagetable;
        this.thumbnail = this.sanitizer.bypassSecurityTrustUrl(retrievedImage);
      }, error => {
        this.thumbnail = '';
        this.thumbnailTest = true;
        // console.log("error" + error);
      });
  }


  getAllimageSign() {
    this.loginService.getAllimageSign()
      .subscribe((imagetable) => {
        const retrievedImage = 'data:image/jpeg;base64,' + imagetable;
        this.imgSin = this.sanitizer.bypassSecurityTrustUrl(retrievedImage);
      }, error => {
        this.imgSin = '';
      });
  }

  ajoutImage(u) {
     this.edituserImg = u;
  }

  public onFileChangedSignature(event) {
    this.selectedFile = event.target.files[0];
    console.log(this.selectedFile);
  }

  public onUploadSignature(u) {
    const uploadImageData = new FormData();
    uploadImageData.append(
      'imageFile',
      this.selectedFile,
      this.selectedFile.name
    );
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
     this.httpClient
      .post(CONFIG.URL + 'signature/upload' +
        '?username=' +
        this.username,
        uploadImageData,
        {
          observe: 'response',
          reportProgress: true,
          headers: headers
        }
      )
      .subscribe(
        response => {
          if (response.status === 200) {
            swal({
              position: 'center',
              title: 'photo ajoutée avec succès.',
              showConfirmButton: true
            });
            this.getAllimageSign();
          } else {
            swal({
              position: 'center',
              type: 'warning',
              title: 'Photo ajouté .',
              showConfirmButton: true
            });
          }
        }
      );

  }

  public onFileChanged(event) {
    console.log(CONFIG.URL);
    // Select File
    this.selectedFile = event.target.files[0];
  }


  getNotifEleve() {

    return this.db.collection('testnotif', ref =>
         ref
         .where('day', '==', this.daynowDate.split('-').reverse().join('-'))
         )// probleme here !! can't read where(....)!!
     .valueChanges().subscribe(res => {

      this.listNotif = res;


      const NumberOfExcpeled =  this.listNotif.filter((element) => element.motif !== 'Abscence' );

       this.countretenu =   NumberOfExcpeled.length;






       this.listNotif.sort((a, b) => (a.time < b.time) ? 1 : -1);



   });
        }


  onUpload() {
    const uploadImageData = new FormData();
    uploadImageData.append('imageFile', this.selectedFile, this.selectedFile.name);
    const decoded = decode(localStorage.getItem('token'));
    const username = decoded.sub;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    this.httpClient.post(CONFIG.URL + 'image/upload' + '?username=' + username, uploadImageData, { observe: 'response' , headers: headers})
      .subscribe((response) => {
        if (response.status === 200) {
          swal({
            position: 'center',
            title: 'photo ajoutée avec succès.',
            showConfirmButton: true
          });
          this.getProfileImg();
        } else {
          this.message = 'Image not uploaded successfully';
        }
      }
      );
  }

  reset() {

  }

  edit(profil: Enseignant) {
    this.ens = profil;
  }

  editprof(email, phone_number, username, password) {
    this.loginService.editUser(this.ens)
      .subscribe((res) => {
        swal({
          position: 'center',
          title: 'Modifcation effectuée avec succès',
          // showConfirmButton: false,
          confirmButtonText: 'OK!',
          timer: 1500
        });
      }),
      error => {
        alert(error);
      };
  }



  openGlobalSocketjs() {
    this.stompClient2.subscribe('/socketjs-publisher', (message) => {
      this.handleResultnote(message);
    });
  }

  handleResultnote(message) {
    const today = new Date();
    const time = today.getHours() - 1 + ':0' + today.getMinutes();
    console.log('time is ' + time);

    if (message.body) {
      const messageResult: Messagenote = JSON.parse(message.body);
      const messageOldResult = messageResult;
      console.log(messageResult);



      if (messageResult.time == time) {
        this.countnote++;
        this.messagesnotes.push(messageResult);
      } else {


      }
    }


  }










  onLoggedout() {
    localStorage.removeItem('token');
  }

/*   sendMessageUsingSocket() {
    let a, b, c;
    let message: Message = { message: a, fromId: b, toId: c };
    this.stompClient.send("/socket-subscriber/send/messageretenu", {}, JSON.stringify(message));

  }

  sendMessageUsingRest() {
    let a, b, c;
    let message: Message = { message: a, fromId: b, toId: c };
    this.socketService.post(message).subscribe(res => {
      console.log(res);
    })

  }
 */
  // initializeWebSocketConnection() {
  //   let ws = new SockJS(this.serverUrl);
  //   this.stompClient = Stomp.over(ws);
  //   let that = this;
  //   this.stompClient.connect({}, function (frame) {
  //     that.isLoaded = true;
  //     that.openGlobalSocket()
  //     //that.openGlobalSocketretenu()
  //   });
  // }



  openGlobalSocket() {
    this.stompClient.subscribe('/socket-publisher', (message) => {
      // this.handleResult(message);
      this.handleResultretenu(message);
    });
  }




  openGlobalSocketretenu() {
    this.stompClient.subscribe('/socket-publisher', (message) => {
      this.handleResultretenu(message);
    });
  }


  handleResultretenu(messageretenu) {
    if (messageretenu.body) {
      const messageResult: Message = JSON.parse(messageretenu.body);
      if (messageResult.toId == 'retenu') {
        this.countretenu++;
        this.messagesretenu.push(messageResult);
      }
      if (messageResult.toId == 'exclus') {
        this.messages.push(messageResult);
        this.countretenu++;
      }
      if (messageResult.toId == 'appreciation') {
        this.messagesappreciation.push(messageResult);
        this.countretenu++;
      }


      for (let i = 0; i < this.messagesretenu.length; i++) {
        const indexretenu = this.messagesretenu[i];

        if (this.messagesretenu[i].toId == 'retenu') {

        } else if (this.messagesretenu[i].toId == 'exclus') { } else if (this.messagesretenu[i].toId == 'appreciation') { } else {
          this.countretenu--;
          this.count--;
          this.countapreciation--;

        }
      }





    }
  }
  initcount() {
    this.count = 0;
  }
  initretenu() {
    this.countretenu = 0;
  }
  initcountnote() {
    this.countnote = 0;
  }
  initapreciation() {
    this.countapreciation = 0;
  }
  getUserbyusername() {

    const decoded = decode(localStorage.getItem('token'));
    this.username = decoded.sub;
    this.loginService.getUserbyusername(this.username)

      .subscribe((user) => {
        this.user = user;
        this.profil = user.profil;
        this.nomuser = this.user.nom;
        this.prenomuser = this.user.prenom;

        if (this.profil == 'Parrain') {

        }
        if (this.profil == 'Enseignant') {

        } else {

        }

      }, error => {
        console.log(error);
      });
  }



  getnotifnote() {

    const today = new Date();
    const timee = today.getHours() + ':' + today.getMinutes();
    console.log('time is ' + timee);
    this.loginService.getNotifnote().subscribe((data) => {

      this.datasource = data;
      //
      for (let i = 0; i < this.datasource.length; i++) {
        if (this.datasource[i].time == timee) {
          this.datasourcetiming.push(this.datasource[i].description);
          console.log('timing source is ' + this.datasourcetiming);

          this.countnote++;
        }

      }
      // console.log("notes"+this.datasource);

    }, error => {
      console.log(error);
    });
  }

}
export class Notebynow {


  public description: any;
  public remarque: any;
  public datenotification: any;
  public time: any;
  public idprogramme: any;
  constructor() {
  }


}
