import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit
} from "@angular/core";
import { routerTransition } from "../../router.animations";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import * as decode from "jwt-decode";
//import { CONFIG } from '../../config';
import { CONFIG } from "../../../environments/environment";

import { UtilisateursService } from "./gestion-utilisateurs.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SitesService } from "./sites.service";
import {HttpClient, HttpEventType, HttpHeaders} from '@angular/common/http';
import { AddNewUserComponent } from "./components/add-new-user/add-new-user.component";
import { MailUserComponent } from "./components/mail-user/mail-user.component";
import { RolesService } from "../gestion-roles/gestion-roles.service";
import { SelectionComponent } from "../../shared/generic-components/selection.component";
import { User } from "../../model/user";
import swal from "sweetalert2";
import { Access } from "../gestion-roles/gestion-roles.component";
import { Enseignant } from "src/app/model/enseignant";
import { LoginService } from "src/app/login/login/login.service";
import { Router } from "@angular/router";

@Component({
  changeDetection: ChangeDetectionStrategy.Default,
  selector: "app-gestion-utilisateurs",
  templateUrl: "./gestion-utilisateurs.component.html",
  styleUrls: ["./gestion-utilisateurs.component.scss"],
  animations: [routerTransition()],
  providers: [UtilisateursService, NgbModal, SitesService],
  entryComponents: [AddNewUserComponent]
})
export class GestionUtilisateursComponent extends SelectionComponent
  implements OnInit {
  closeResult: string;
  t = "";
  bcheck: any;
  list = [
    { id: 1, name: "---Tous---" },
    { id: 2, name: "Admin" },
    { id: 3, name: "Parent" },
    { id: 4, name: "Enseignant" },
    { id: 5, name: "Surveillant" }
  ];
  access: Access = new Access();
  private lists = [];
  enabled: boolean = false;
  statut: any;
  uploded = false;
  utilisateurs: any[];
  profil: any;
  currentPage: number = 1;
  sites: any[] = [];
  edituser: Enseignant = new Enseignant();
  edituserImg: Enseignant = new Enseignant();
  user: User = new User();
  username: any;
  numInsc: string;
  selectedFile: File;
  //sorting
  key: string = "nom"; //set default
  reverse: boolean = false;
  i: number;
  private current: number;
  count: number;
  loading :boolean =false ;
  constructor(
    private sitesService: SitesService,
    private userService: UtilisateursService,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal,
    private rolesService: RolesService,
    private httpClient: HttpClient,
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    public router: Router
  ) {
    super();
  }

  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  ngOnInit() {
    this.rolesService.getAllAuthorities().subscribe(
      authorities => {
        this.lists = authorities;
      },
      error => {
        console.log(error);
      }
    );
    this.userService.getAllUtilisateur().subscribe(
      utilisateurs => {
        this.utilisateurs = utilisateurs;

      },
      error => {
        alert(error);
      }

    );

    this.cdr.detectChanges();
    if (localStorage.getItem("token") != null) {
      var decoded = decode(localStorage.getItem("token"));
      this.username = decoded.sub;
    }


    this.accessdenied();
    this.getbyusername();
  }

 getbyusername(){
  this.loading=true ;
  var decoded = decode(localStorage.getItem("token"));
  this.username = decoded.sub;
  this.loginService.getUserbyusername(this.username).subscribe((res=>
    {
      this.user = res;
                if (this.user.profil == "Parrain") {
                  this.router.navigate(["/espaceParent"]);
                } else if (this.user.profil == "Enseignant") {
                  this.router.navigate(["./mon-emplois"]);
                }else if (this.user.profil == "Eleve") {
                  this.router.navigate(["./espaceEleve"]);
                }
    }))
    this.loading=false;
}

  ajoutImage(u) {
    this.edituserImg = u;
    this.numInsc = u.username;
    // console.log(this.username);
  }


  public onFileChangedSignature(event) {
    console.log('onFileChangedSign');
    this.selectedFile = event.target.files[0];
    this.uploded = true;
    console.log(this.selectedFile);
  }



  public onUpload(u) {
    const uploadImageData = new FormData();
    uploadImageData.append(
      "imageFile",
      this.selectedFile,
      this.selectedFile.name
    );
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
   this.httpClient
      .post(CONFIG.URL + "signature/upload" +
        "?username=" +
        this.numInsc,
        uploadImageData,
        {
          observe: "response",
          reportProgress: true,
          headers: headers
        }
      )
      .subscribe(
        response => {
          if (response.status === 200) {
            swal({
              position: "center",
              title: "La photo est ajoutée avec succès.",
              showConfirmButton: true

            });
          } else {
            swal({
              position: "center",
              type: "warning",
              title: "Photo ajouté .",
              showConfirmButton: true
            });
            /* this.message = "Image not uploaded successfully";*/
          }
        }
        /*event => {
          if (event.type === HttpEventType.UploadProgress) {
            const percentDone = Math.round((100 * event.loaded) / event.total);
            console.log(event);
          }
        }*/
      );
  }

  detailUser(u) {
    this.edituser = u;
  }

  editUser(u) {
    this.edituser = u;
    this.bcheck = this.edituser.enabled;
  }
  detail(u) {
    this.edituser = u;
    this.bcheck = this.edituser.enabled;
  }

  mailUser(id) {
    let options: NgbModalOptions = { size: "lg" };
    const modalRef = this.modalService.open(MailUserComponent, options);
    modalRef.componentInstance.userId = id;
  }

  getCadreAdmin() {
    if (localStorage.getItem("token") != null) {
      var decoded = decode(localStorage.getItem("token"));
      this.username = decoded.sub;
      this.userService.getAllUtilisateur().subscribe(
        utilisteur => {
          this.utilisateurs = utilisteur;
        },
        error => {
          console.log(error);
        }
      );
    }
  }
  deletUser(user: User) {
    swal({
      title: "Voulez vous supprimer ce cadre administratif ?",
      text: "Vous ne pouvez pas revenir en arrière si vous supprimez ce cadre administratif!",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "Annuler",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui"
    }).then(result => {
      if (result.value) {
        swal("Supprimé!", "Vos données  ont  été supprimées.");
        this.count = null;
        this.userService.archiveUser(user.id).subscribe(res => {

          if (res["response"]) {
            this.getCadreAdmin();
          }
        });
      }
    });
  }
  reset() {
    this.getCadreAdmin();
  }

  deleteUser() {

    if (this._selected.length == 0) {
      swal(
        "<h2 >il faut que tu selectionne au minimum un utilisteur !</h2>",
        "warning"
      );
    } else {
      swal({
        title: "Êtes-vous sûr?",
        text: "Vous ne pouvez pas revenir en arrière!",
        showCancelButton: true,
        cancelButtonText: "Annuler",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, supprimez-le(s)!"
      }).then(result => {
        if (result.value) {
          swal("Supprimé!", "Vos données ont été supprimées.");
          this.count = null;

          this.userService.archiverUser(this._selected).subscribe(res => {

            if (res["response"]) {
              this.getCadreAdmin();
            }
          });
        }
      });
    }
  }
  blockuser(checked) {
    this.edituser.enabled = checked;

    if (checked == false) {
      this.userService
        .blockUser(this.edituser.username, false)
        .subscribe(res => {
          this.enabled = true;
        }),
        error => {
          alert(error);
        };
    } else {
      this.userService
        .blockUser(this.edituser.username, true)
        .subscribe(res => {
          this.enabled = false;
        }),
        error => {
          alert(error);
        };
    }
  }
  checkboxes(u: User) {
    //this.eleve=u;

    var inputElems = document.getElementsByTagName("input");
    var tr = document.getElementsByTagName("tr");
    this.count = 0;

    for (var i = 0; i <= this.utilisateurs.length; i++) {
      if (inputElems[i].type == "checkbox" && inputElems[i].checked == true) {
        this.count++;

        tr[i].style.backgroundColor = "lightblue";
        // alert(this.count);
      } else if (
        inputElems[i].type == "checkbox" &&
        inputElems[i].checked == false
      ) {
        tr[i].style.backgroundColor = "";
      }
    }
  }
  getAccess(profil: any) {
    this.rolesService
      .getAccess("Gestion des utilisateurs", this.user.profil)
      .subscribe(
        access => {
          this.access = access;
        },
        error => {
          console.log(error);
        }
      );
  }
  accessdenied() {
    if (localStorage.getItem("token") != null) {
      var decoded = decode(localStorage.getItem("token"));

      this.username = decoded.sub;
      this.rolesService.getUserbyusername(this.username).subscribe(
        user => {
          this.user = user;

          this.getAccess(user.profil);
        },
        error => {
          console.log(error);
        }
      );
    }
  }
  editUsers() {
    this.userService.editUser(this.edituser).subscribe(
      res => {
        if (res != null) {
          swal({
            position: 'center',
            title: 'Mise à jour effectuée avec succès.  ',
            //showConfirmButton: false,
            confirmButtonText: 'OK!',
            timer: 1500
          })
          console.log(res)
        }
      },
        error => {
          alert(error);
        });
  }

  makePass2() {
    //this.t = Math.random().toString(36).slice(-8) + Math.random().toString(36).slice(2);
    var possible = "012#%&+@3456789#%&+@ABCDEFGH#%&+@IJKLMNOPQRST#%&+@0123456#%&+@789UVWXYZabcdefghijklmnopqrs#%&+@tuvwxyz012#%&+@3456789";
    this.t = "";
    for (var i = 0; i < 11; i++) {
      this.t += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    this.edituser.password = this.t;

  }

}
