import { Parrain } from "src/app/model/parrain";

export class Eleve {

    public nom: string;
    public prenom : string ;
    public niveauEtude : string ;
    public classeActuel : string ;
    public date_naissance : Date ;
    public sexe : string ;
    public etat :string ;
    public cin : number ;
    public situationFamiliale : string ;
    public remarque: string;
    public numInscription :string ;
    public username:any ;
    public parrain : Parrain ;
    public constructor() {
    }
}
