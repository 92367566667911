import { Component, OnInit, ViewChild } from "@angular/core";
import { EspaceParrainService } from "../../service/espace-parrain.service";
import { EleveParrain } from "../../service/eleve-parrain";
import * as decode from "jwt-decode";
import { MatTableDataSource, MatPaginator } from "@angular/material";
// import { Input } from 'Ancien/node_modules_old/@angular/core';
import { routerTransition } from "../../../router.animations";
import { Eleveservice } from "../../../layout/gestion-eleves/gestion-eleves.service";
//import { CONFIG } from 'src/app/config';
import { CONFIG } from "../../../../environments/environment";
import { Http, Response, Headers } from "@angular/http";
import { forkJoin } from "rxjs";
import { Observable } from "rxjs";
import { LoginService } from "src/app/login/login/login.service";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-list-eleve",
  templateUrl: "./list-eleve.component.html",
  styleUrls: ["./list-eleve.component.scss"],
  animations: [routerTransition()],
})
export class ListEleveComponent implements OnInit {
  constructor(
    private _http: HttpClient,
    private espaceParrainService: EspaceParrainService,
    private eleveService: Eleveservice,
    private loginService: LoginService,
    public router: Router
  ) {}

  // @Input() eleveCourant: string;

  listEleve: EleveParrain[];
  username;
  imgEleve: any;
  AllImgEleve = [];
  listEleveWithImg: any;
  loading = false;
  user: any;
  valua = "";
  AllAbcenceEleve = [];
  AllPenalEleve = [];
  AllApprecEleve = [];
  public dataSource = new MatTableDataSource<EleveParrain>();
  displayedColumns: string[] = [
    "numInscription",
    "nom",
    "prenom",
    "classeActuel",
    "dateNaissance",
    "emploiTemps",
    "absence",
    "penalite",
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit() {
    this.getbyusername();
    if (localStorage.getItem("token") != null) {
      const decoded = decode(localStorage.getItem("token"));
      this.username = decoded.sub;
    }
    this.getListEleve();

    this.dataSource.paginator = this.paginator;
  }

  getbyusername() {
    this.loading = true;
    const decoded = decode(localStorage.getItem("token"));
    this.username = decoded.sub;
    this.loginService.getUserbyusername(this.username).subscribe((res) => {
      this.user = res;
      if (this.user.profil == "Enseignant") {
        this.router.navigate(["./mon-emplois"]);
      } else if (this.user.profil == "Directeur") {
        this.router.navigate(["DashboardDirecteur"]);
      } else if (this.user.profil == "Eleve") {
        this.router.navigate(["./espaceEleve"]);
      }
    });
    this.loading = false;
  }

  // getAllImgEleve(): Observable<any[]> {
  //   const resALL = [];
  //   const token = localStorage.getItem("token");
  //   const headers = new HttpHeaders().set("Authorization", "Bearer " + token);
  //   this.listEleve.forEach((ithem, key) => {
  //     const url =
  //       CONFIG.URL +
  //       "image/imagebyNumInscription" +
  //       "?numinscription=" +
  //       ithem.numInscription;
  //     resALL.push(this._http.get(url, { headers: headers }));
  //   });
  //   console.log("resAll", forkJoin(resALL));
  //   return forkJoin(resALL);
  // }
  // getImgEleve(item): Observable<any> {
  //   const resALL = [];
  //   const token = localStorage.getItem("token");
  //   const headers = new HttpHeaders().set("Authorization", "Bearer " + token);
  //   this.listEleve.forEach((ithem, key) => {
  //   const url =
  //     CONFIG.URL + "image/imagebyNumInscription" + "?numinscription=" + item;
  //   console.log(url);
  //   resALL.push(
  //    this._http.get(url, { headers: headers }))
  //   });
  //   console.log("resAll", forkJoin(resALL));
  //   return forkJoin(resALL);
  // }
  getAllAbcenceEleve(): Observable<any[]> {
    const resALL = [];
    const token = localStorage.getItem("token");
    const headers = new HttpHeaders().set("Authorization", "Bearer " + token);
    this.listEleve.forEach((ithem, key) => {
      const url =
        CONFIG.URL +
        "api/absence/countabsenceeleve" +
        "?numinscri=" +
        ithem.numInscription;
      console.log(url);
      resALL.push(this._http.get(url, { headers: headers }));
    });
    return forkJoin(resALL);
  }

  getAllPenalEleve(): Observable<any[]> {
    const token = localStorage.getItem("token");
    const headers = new HttpHeaders().set("Authorization", "Bearer " + token);
    const resALL = [];
    this.listEleve.forEach((ithem, key) => {
      const url =
        CONFIG.URL +
        "api/penalites/countpenaliteeleve" +
        "?numinscri=" +
        ithem.numInscription;
      resALL.push(this._http.get(url, { headers: headers }));
    });
    return forkJoin(resALL);
  }

  getAllaprecEleve(): Observable<any[]> {
    const resALL = [];
    const token = localStorage.getItem("token");
    const headers = new HttpHeaders().set("Authorization", "Bearer " + token);
    this.listEleve.forEach((ithem, key) => {
      const url =
        CONFIG.URL +
        "api/appreciation/countappreciationprof" +
        "?numinscri=" +
        ithem.numInscription;
      console.log(url);
      resALL.push(this._http.get(url, { headers: headers }));
    });
    return forkJoin(resALL);
  }

  // getAllImgEleveOne() {
  //   this.getAllAbcenceEleve().subscribe((data) => {
  //     this.AllAbcenceEleve = data;
  //   });
  //   this.getAllPenalEleve().subscribe((data) => {
  //     this.AllPenalEleve = data;
  //   });
  //   this.getAllaprecEleve().subscribe((data) => {
  //     this.AllApprecEleve = data;
  //   });
  //   this.getAllImgEleve().subscribe((data) => {
  //     this.AllImgEleve = data;
  //     console.log(this.AllImgEleve);
  //     const listEleveWithImg = [];
  //     for (let i = 0; i < this.listEleve.length; i++) {
  //       listEleveWithImg.push({
  //         nom: this.listEleve[i].nom,
  //         prenom: this.listEleve[i].prenom,
  //         dateNaissance: this.listEleve[i].dateNaissance,
  //         numInscription: this.listEleve[i].numInscription,
  //         classeActuel: this.listEleve[i].classeActuel,
  //         img: "data:image/jpeg;base64," + this.AllImgEleve[i],
  //         nbrAbs: this.AllAbcenceEleve[i],
  //         nbrPenal: this.AllPenalEleve[i],
  //         nbrApprec: this.AllApprecEleve[i],
  //       });
  //     }
  //     console.log("__________________");
  //     this.listEleveWithImg = listEleveWithImg;
  //     console.log(listEleveWithImg);
  //   });
  // }

  getListEleve(): void {
    this.espaceParrainService
      .getElevesByParrain(this.username)
      .subscribe((eleves) => {
        console.log(eleves);
    
        this.listEleve = eleves;
        this.dataSource.data = this.listEleve;
        this.getAllAbcenceEleve().subscribe((data) => {
          this.AllAbcenceEleve = data;
        });
        this.getAllPenalEleve().subscribe((data) => {
          this.AllPenalEleve = data;
        });
        // this.getAllImgEleveOne()
        this.getAllaprecEleve().subscribe((data) => {
          this.AllApprecEleve = data;
        });
        this.getAllAbcenceEleve();
      });
  }

  getImg(numIns) {
    this.eleveService.getImgEleve(numIns).subscribe((data) => {
      console.log(data);
      if(data!==null){
        this.imgEleve = 'data:image/png;base64,' + data.picByte;
      }
      
      console.log(this.imgEleve);
    });
   
  }
}
