import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutRoutingModule } from './layout-routing.module';
import { LayoutComponent } from './layout.component';
import { TopnavbarComponent } from '../shared/topnavbar/topnavbar.component';
import { FooternavbarComponent } from '../shared/footernavbar/footernavbar.component';
import { AsidenavComponent } from '../shared/asidenav/asidenav.component';
import { SettingnavbarComponent } from '../shared/settingnavbar/settingnavbar.component';
import { NavbarParentComponent } from '../espace-parent/navbar-parent/navbar-parent.component';
import { EspaceParentComponent } from '../espace-parent/espace-parent.component';
import { DashboardParrainComponent } from '../espace-parent/dashboard-parrain/dashboard-parrain.component';
import { FooterBarParrainComponent } from '../espace-parent/footer-bar-parrain/footer-bar-parrain.component';
import { EspaceParentModule } from '../espace-parent/espace-parrain.module';
import { GestionUtilisateursModule } from './gestion-utilisateurs/gestion-utilisateurs.module';
import { GestionEnseignantComponent } from './gestion-enseignant/gestion-enseignant.component';
import { AddComponent } from './gestion-enseignant/add/add.component';
import { UpdateComponent } from './gestion-enseignant/update/update.component';
import { GestionNiveauComponent } from './gestion-niveau/gestion-niveau.component';
import { AddNiveauComponent } from './gestion-niveau/add-niveau/add-niveau.component';
import { EditNiveauComponent } from './gestion-niveau/edit-niveau/edit-niveau.component';
import { HistoriqueComponent } from './historique/historique/historique.component';
import { InjectionEmploisComponent } from './injection-emplois/injection-emplois.component';
import { SharedModule } from '../SharedModule/SharedModule';
import { GestionAvertissementComponent } from './gestion-avertissement/gestion-avertissement.component';
import { GestionBoiteLettreComponent } from './gestion-boite-lettre/gestion-boite-lettre.component';
import { AddBoiteLettreComponent } from './add-boite-lettre/add-boite-lettre.component';
import { EnvoyerBoiteLettreComponent } from './envoyer-boite-lettre/envoyer-boite-lettre.component';
import { MatSortModule } from '@angular/material/sort';
import { GestionViolenceComponent } from './gestion-violence/gestion-violence.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ArchvierBoiteLettreComponent } from './archvier-boite-lettre/archvier-boite-lettre.component';
import { DetailBoiteLettreComponent } from './detail-boite-lettre/detail-boite-lettre.component';
import { BrouillonsBoiteLettreComponent } from './brouillons-boite-lettre/brouillons-boite-lettre.component';
import { ReleveDesNotesEnsComponent } from './releve-des-notes-ens/releve-des-notes-ens.component';
import { SharedPipesModule } from '../shared/pipes/shared-pipes.module';
import { ModifiernotesComponent } from './modifiernotes/modifiernotes.component';
import { SemaineGenererComponent } from './semaine-generer/semaine-generer.component';
import { UpdateTravailDemandeComponent } from './travail-demandes/update-travail-demande/update-travail-demande.component';
import { ReleveMoyennesEnseignantComponent } from './releve-moyennes-enseignant/releve-moyennes-enseignant.component';











@NgModule({
    imports: [
        CommonModule,
        MatSortModule,
        LayoutRoutingModule, GestionUtilisateursModule,
        FormsModule,
        ReactiveFormsModule,
        EspaceParentModule, SharedPipesModule


    ],
    exports: [TopnavbarComponent,
        AsidenavComponent,
        FooternavbarComponent,
        SettingnavbarComponent,
         ],
    declarations: [TopnavbarComponent,
        AsidenavComponent,
        FooternavbarComponent,
        SettingnavbarComponent,
        LayoutComponent,

        ReleveDesNotesEnsComponent,

        ReleveMoyennesEnseignantComponent,
    

 

    ],
    providers: [
    ]

})
export class LayoutModule { }
