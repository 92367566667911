import { Injectable, OnInit } from '@angular/core';
// import { CONFIG } from 'src/app/config';
import { CONFIG } from '../../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { EleveParrain } from './eleve-parrain';
import { Http, Response, Headers } from '@angular/http';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { catchError, retry } from 'rxjs/operators';
import { ConfigService } from '../config/config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EleveEmploi } from './eleve-emploi';
import { ElevePenalite } from './eleve-penalite';
import { EleveAbsence } from './eleve-absence';
import { Programme } from 'src/app/layout/gestion-emplois/programme';
import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EspaceParrainService implements OnInit {
  constructor(private http: HttpClient, private configService: ConfigService, private _http: HttpClient) {

  }

  eleveParr: any;

  listEleve: EleveParrain[];

  url: string = CONFIG.URL + CONFIG.apiPath;


  url2: string = CONFIG.URL + CONFIG.apiPath + 'emplois/add?idprogramme=';


  urlsemaine: string = CONFIG.URL + CONFIG.apiPath + 'annee/allsemaine';


  urlp: string = CONFIG.URL + CONFIG.apiPath + 'programmes/programme/pointage';

  ngOnInit() {
    this.url = CONFIG.URL + CONFIG.apiPath;

  }

  getElevesByParrain(username): Observable<EleveParrain[]> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get<EleveParrain[]>(this.url + 'Parrain/allbyParrain?username=' + username, { headers: headers });
  }



  /* getViolenceTypeSexeLast(): Observable<any[]> {
    let headers = new Headers();
    var decoded = decode(localStorage.getItem('token'));
    this.createAuthorizationHeader(headers);

    let resALL = [];
    this.violenceTypeTab.forEach((ithem, key) => {
      let url = CONFIG.URL + CONFIG.apiPath + 'violence/getAllbyViolence?violence=' + ithem;
      resALL.push(this._http.get(url).map((res) => res.json()));
    });
    return forkJoin(resALL);
  }
*/


  add(id: string, titre: string, texte: string): Observable<any[]> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this._http.post<any>(this.url2 + id, ({
      note: titre, remarque: texte
    } || null), { headers: headers });
  }
  getAllSemaine(): Observable<any[]> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this._http.get<any>(this.urlsemaine, { headers: headers });
  }

  getAllHeures(): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get(this.url + 'emplois/allheures', { headers: headers })
      .pipe(retry(3));
  }

  getEmploiEleve(num: string): Observable<EleveEmploi[]> {

    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get<EleveEmploi[]>(this.url + `programmes/programme/byinscription?Num_Inscription=${num}`, { headers: headers });
  }

  getEmploiCLasse(id: any): Observable<EleveEmploi[]> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get<EleveEmploi[]>(this.url + `programmes/programme/classe?Num_Inscription=${id}`, { headers: headers });
  }



  getEmploiCLasseSemaine(nomClasse: any, semaine): Observable<EleveEmploi[]> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    console.log(this.url + `programmes/programme/byclassebysemaine?classe=${nomClasse}&semaine=${semaine.semaine}`);
    return this.http.get<EleveEmploi[]>(this.url + `programmes/programme/byclassebysemaine?classe=${nomClasse}&semaine=${semaine.semaine}`, { headers: headers });
  }


  getEmploiProf(username: any): Observable<EleveEmploi[]> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get<EleveEmploi[]>(this.url + `programmes/programme/enseignant?username=${username}`, { headers: headers });
  }


  getEmploiProfSemaine(username: any, semaine: any): Observable<EleveEmploi[]> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    console.log(this.url + `programmes/programme/byEnseignant?username=${username}&semaine=${semaine.semaine}`);
    return this.http.get<EleveEmploi[]>(this.url + `programmes/programme/byEnseignant?username=${username}&semaine=${semaine.semaine}`, { headers: headers });
  }



  getEmploiProfSemaine0(username: any, semaine: any): Observable<EleveEmploi[]> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get<EleveEmploi[]>(this.url + 'programmes/programme/byEnseignant?username=' + username + '&semaine=' + semaine.semaine, { headers: headers });
  }

  editProgramme(id: any): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    console.log(id);
    return this.http.get(this.urlp + `?id=` + id, { headers: headers });

  }
  getListPenaliteEleveByNuminscr(numInsc: string): Observable<ElevePenalite[]> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get<ElevePenalite[]>(this.url + `Parrain/allPenalitesByEleves?numInscription=${numInsc}`, { headers: headers });
  }

  getListAbsenceEleveByNuminsc(numInsc: string): Observable<EleveAbsence[]> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get<EleveAbsence[]>(this.url + `Parrain/allabsencebyeleve?numInscription=${numInsc}`, { headers: headers });
  }

  getAllTDByNumInscrit(numInsc: string, trimestre: string): Observable<any[]> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get<any[]>(this.url + `ulpoadTravail/getTDByNumInscrit?numInscrit=${numInsc}&nomTrimestre=${trimestre}`, { headers: headers });
  }

  //   getTrimestre(): Observable<any[]> {
  //     const token = localStorage.getItem('token');
  //     const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
  //     return this.http.get<any[]>(this.url + `moyenne/trimestre`, {headers: headers});
  // }

  getListAppreEleveByInsc(numInsc: string): Observable<EleveAbsence[]> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get<EleveAbsence[]>(this.url + `appreciation/allbynuminscription?numinscri=${numInsc}`, { headers: headers });
  }

  emploiSalleParSemaine(nomSalle: any, semaine): Observable<EleveEmploi[]> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    console.log(this.url + `emplois/getemploiSalleBySemaine?nomSalle=${nomSalle}&semaine=${semaine.semaine}`);
    return this.http.get<EleveEmploi[]>(this.url + `emplois/getemploiSalleBySemaine?nomSalle=${nomSalle}&semaine=${semaine.semaine}`, { headers: headers });
  }

  getTravailById(id: any) {
    const token = localStorage.getItem("token");
    const headers = new HttpHeaders().set("Authorization", "Bearer " + token);
    const url = CONFIG.URL + CONFIG.apiPath;
    return this._http.get<any>(this.url + `ulpoadTravail/byId/?id=${id}`, {
      headers: headers,
    });
  }

  downloadFile(filename: string):Observable<any>{
    const token = localStorage.getItem("token");
    const headers = new HttpHeaders().set("Authorization", "Bearer " + token);
    const url = CONFIG.URL + CONFIG.apiPath+`ulpoadTravail/getFileByName/?filename=${filename}`;
    return this.http.get<any>(url, {headers: headers})
  }

  


}
