import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EspaceEleveRoutingModule } from './espace-eleve-routing.module';
import { EspaceEleveComponent } from './espace-eleve.component';
import { AsidenavEleveComponent } from './asidenav-eleve/asidenav-eleve.component';
import { FooterBarEleveComponent } from './footer-bar-eleve/footer-bar-eleve.component';
import { NavbarEleveComponent } from './navbar-eleve/navbar-eleve.component';
import { CantineEleveComponent } from './cantine-eleve/cantine-eleve.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedPipesModule } from '../shared/pipes/shared-pipes.module';
import { from } from 'rxjs';
import { ReleveDesNotesComponent } from './releve-des-notes/releve-des-notes.component';
@NgModule({
  declarations: [
    EspaceEleveComponent,
    AsidenavEleveComponent,
    FooterBarEleveComponent,
    NavbarEleveComponent,
    CantineEleveComponent,
    ReleveDesNotesComponent
  ],
  imports: [
    CommonModule, EspaceEleveRoutingModule, FormsModule, ReactiveFormsModule, SharedPipesModule
  ], exports: [
    EspaceEleveComponent,
    AsidenavEleveComponent,
    FooterBarEleveComponent,
    NavbarEleveComponent,
    CantineEleveComponent
  ]
})
export class EspaceEleveModule { }
