import { Etablissement } from "../layout/rapport-statistique/statistique-service.service";
import { Matiere } from "../layout/gestion-matieres/matiere";

export class Enseignant {
public anciennete: any ;
public archiver: any ;
public cin: any ;
public date_naissance: any ;
public datedembauche: any ;
public email: any ;
public enabled: false
public etablissement: Etablissement ;
public id: any;
public lastPasswordResetDate: any ;
public matiere: Matiere ;
public nbrheures: any ;
public nom: any ;
public nombreHeureClasseFinale: any ;
public nombreHeureEffective: any ;
public nombreHeureSoutien: any ;
public nombreHeureSupplimentaire: any ;
public num_passport: any ;
public parain: any ;
public password: any ;
public photo: any ;
public prenom: any ;
public profil: any ;
public remarque: any ;
public sexe: any ;
public situationFamiliale: any ;
public telephone: any ;
public username: any ;
public verou: any ;
constructor() {
}

}