import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { CONFIG } from '../../../environments/environment';
import * as decode from 'jwt-decode';
import { User } from '../../model/user';
import {HttpClient, HttpHeaders} from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class FormuleNoteService {

  constructor(private _http: HttpClient, private http: HttpClient) { }
  url78: string = CONFIG.URL + CONFIG.apiPath + 'epreuve/all';
  url2: string = CONFIG.URL + CONFIG.apiPath + 'notes/updatenote';

  editNote(id: any, valeur: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);

    return this._http.put<any>(this.url2, ({valeur : valeur , id: id }), { headers: headers });
  }


  getAllFormules(): Observable<any[]> {
    const decoded = decode(localStorage.getItem('token'));
    const username = decoded.sub;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    const url = CONFIG.URL + CONFIG.apiPath + 'formules/allformule';
    return this._http.get<any>(url, { headers: headers });
  }

  getAllNivaux(username): Observable<any[]> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    const url = CONFIG.URL + CONFIG.apiPath + 'class/alls?username=' + username;
    return this._http.get<any>(url, { headers: headers });
  }

  getMatByClasse(classeActuel): Observable<any[]> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    const urlns = CONFIG.URL + CONFIG.apiPath + 'emplois/getAllMatiereByClasse?nomClasse=' + classeActuel;
    return this._http.get<any>(urlns, { headers: headers });
  }
  getUserbyusername(username: string): Observable<User> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    const url6 = CONFIG.URL + CONFIG.apiPath + 'user/getbyusername' + '?username=' + username;
    let a: any;
    return a = this._http.get<User>(url6, { headers: headers });
  }

  getAllmatiere(): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    const url6123 = CONFIG.URL + CONFIG.apiPath + 'matiere/all';
    let a: any;
    return a = this._http.get(url6123, { headers: headers });
  }
  getAllClasses(): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    const url6 = CONFIG.URL + CONFIG.apiPath + 'class/all';
    
    return this._http.get(url6, { headers: headers });
  }
  getAllEpreuve(): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    const url78 = CONFIG.URL + CONFIG.apiPath + 'epreuve/all';
    let a: any;
    return a = this._http.get(url78, { headers: headers });
  }

  getFormCME(classe, matiere, epreuve): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    const url6 = CONFIG.URL + CONFIG.apiPath + 'formules/allbyclassebymatierebyepreuve?nomclasse=' + classe + '&nommatiere=' + matiere + '&epreuve=' + epreuve;
    return this._http.get(url6, { headers: headers });
  }

  getAllEpreuveByMatiereClasse(classe, matiere): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    const url78 = CONFIG.URL + CONFIG.apiPath + 'formules/allEpreuvesFromFormule?nomclasse='+classe+ '&nommatiere='+matiere;
    let a: any;
    return a = this._http.get(url78, { headers: headers });
  }

  AddFormNote(matiere: string, classe: string, epreuve: number, coefficient: string, obligatoire: string): Observable<any[]> {
    console.log(matiere, classe, epreuve, coefficient, obligatoire);
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    const url = CONFIG.URL + CONFIG.apiPath + 'formules/addformule?classe=' + classe + '&epreuveId=' + epreuve + '&matiere=' + matiere;
    return this._http.post<any>(url, ({
      coefficient: coefficient, obligatoire: obligatoire,
    } || null), { headers: headers });
  }



  editFormule(coefficient, id): Observable<any[]> {
    console.log(coefficient, id);
    const url = CONFIG.URL + CONFIG.apiPath + 'formules/updateformule';
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this._http.put<any>(url, ({
      coefficient: coefficient, id: id,
    }));
  }

  getAllTeachers(): Observable<any[]> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
      const decoded = decode(localStorage.getItem('token'));
      const username = decoded.sub;

      return this.http.get<any[]>(CONFIG.URL + CONFIG.apiPath + `enseignants/allbyetab?username=${username}`, { headers: headers });
  }

  getClassesByTeacher(username: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
      const url = CONFIG.URL + CONFIG.apiPath;
      return this.http.get<any[]>(url + `emplois/getAllClasseByProf?username=${username}`, {headers: headers});
  }
  
  findClassesByTeacher(username: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
      const url = CONFIG.URL + CONFIG.apiPath;
      return this.http.get<any[]>(url + `emplois/getAllClassesByTeacher?username=${username}`, {headers: headers});
  }

  /*Interface Relèver des notes */

  getAllClasse(): Observable<any> {
    const decoded = decode(localStorage.getItem('token'));
    const username = decoded.sub;
    const url = CONFIG.URL + CONFIG.apiPath + 'statusclasse/all';
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);    return this._http.get(url, { headers: headers });
  }
  //  notes/Allbymatierebyclassebytrimestre?matiere=Anglais&classe=9b2&trimestre=trimestre1
  getNotesEleve(matiere, trimestre, classe): Observable<any[]> {
    const url = CONFIG.URL + CONFIG.apiPath;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get<any[]>(url + `notes/Allbymatierebyclassebytrimestre?matiere=${matiere}&classe=${classe}&trimestre=${trimestre}`, {headers: headers});
  }


  getNotesEleveByEpreuve(matiere, classe, trimestre , epreuve): Observable<any[]> {
    const url = CONFIG.URL + CONFIG.apiPath;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get<any[]>(url + `notes/Allbymatierebyclassebytrimestrebyepreuve?matiere=${matiere}&classe=${classe}&trimestre=${trimestre}&epreuve=${epreuve}`, {headers: headers});
  }




  getAllElevesbySelectedClass(nomclasse: string): Observable<any[]> {
    const url115 =
      CONFIG.URL +
      CONFIG.apiPath +
      'eleve/allbyclass/' +
      '?nomclasse=' +
      nomclasse;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this._http
      .get<any>(url115, { headers: headers });
  }


  getAllStatutMat(): Observable<any[]> {
    const url = CONFIG.URL + CONFIG.apiPath;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get<any[]>(url + `statusmatirer/all`, {headers: headers});
  }

  getAllEpreuveByClassAndMatiere(classeId:any, matiereId:any):Observable<any[]>{
    const url = CONFIG.URL + CONFIG.apiPath;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get<any[]>(url+ `formules/allEpreuvesFromFormule?classe=${classeId}&matiere=${matiereId}`, {headers: headers})
  }

  getNoteByClassAndEpreuve(classeId:any, epreuveId:any): Observable<any[]> {
    const urlns = CONFIG.URL + CONFIG.apiPath + 'notes/allNotesByClassAndEpreuve?classeId=' + classeId + '&epreuveId=' + epreuveId;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this._http.get<any[]>(urlns, {headers: headers});
  }

  updateNote(note:any): Observable<any>{
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    const urlns = CONFIG.URL + CONFIG.apiPath + `notes/update/${note.id}`
    return this._http.put<any>(urlns, note,{headers:headers});
  }

  findEpreuvesByClasseAndMatiere(classe: string, matiere: string){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    const url = CONFIG.URL + CONFIG.apiPath + 'epreuve/getByClassAndMatiere?classe=' + classe + '&matiere=' + matiere;
    return this._http.get<any>(url, {headers:headers});
  }
  
  findMatieresByClasse(classe: string){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    const url = CONFIG.URL + CONFIG.apiPath + 'emplois/findMatieresByClasse?nomClasse=' +  classe;
    return this._http.get<any>(url, {headers:headers});
  }
//relevé note (epreuve)
  findAllNotesByEleveClasseTrimestreMatiere(eleveId: number, classeId:number, trimestre:string, matiereId: number): Observable<any>{
    const urlns = CONFIG.URL + CONFIG.apiPath + 'notes/allNotesByEleveClasseTrimestreMatiere?classeId=' + classeId +'&eleveId=' + eleveId + '&matiereId=' + matiereId + '&trimestre=' + trimestre;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this._http.get<any>(urlns, {headers: headers});
  }
//relevé moyenne matiere ESPACE DIRECTEUR
  findAllMoyenneMatiereByEleveClasseTrimestr(eleveId: number, classeId:number, trimestre:string): Observable<any>{
    const urlns = CONFIG.URL + CONFIG.apiPath + 'moyenneMatiere/allMoyenneByEleveClasseTrimestre?classeId=' + classeId +'&eleveId=' + eleveId + '&trimestre=' + trimestre;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this._http.get<any>(urlns, {headers: headers});
  }
//relevé moyenne matiere ESPACE ENSEIGNANT
findAllMoyenneMatiereByEleveClasseTrimestreMatiere(eleveId: number, classeId:number, trimestre:string, matiereId: number): Observable<any>{
  const urlns = CONFIG.URL + CONFIG.apiPath + 'moyenneMatiere/allMoyenneByEleveClasseTrimestreMatiere?classeId=' + classeId +'&eleveId=' + eleveId + '&matiereId=' + matiereId + '&trimestre=' + trimestre;
  const token = localStorage.getItem('token');
  const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
  return this._http.get<any>(urlns, {headers: headers});
}

//relevé moyenne trimestre

findAllMoyenneTrimesrtreByEleveClasse(eleveId: number, classeId:number): Observable<any>{
  const urlns = CONFIG.URL + CONFIG.apiPath + 'moyenneTrimestre/allMoyenneTrimestreByEleveClasse?classeId=' + classeId +'&eleveId=' + eleveId;
  const token = localStorage.getItem('token');
  const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
  return this._http.get<any>(urlns, {headers: headers});
}
//relevé moyenne annuelle 
findAllMoyenneAnnuelleByEleveClasse(eleveId: number, classeId:number): Observable<any>{
  const urlns = CONFIG.URL + CONFIG.apiPath + 'moyenneAnnuelle/allMoyenneAnnuelleByEleveClasse?classeId=' + classeId +'&eleveId=' + eleveId;
  const token = localStorage.getItem('token');
  const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
  return this._http.get<any>(urlns, {headers: headers});
}

}
export class Note {
  id: number ;
  valeur: any ;
  date: any ;
  public constructor() {
 }

}
