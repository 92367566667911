import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2';
import { EspaceParrainService } from '../../service/espace-parrain.service';
import { routerTransition } from './../../../../app/router.animations';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
  animations: [routerTransition()],
  providers: [DatePipe]
})
export class DetailComponent implements OnInit {
  router: any;
  constructor(private datePipe: DatePipe, private activateRoute: ActivatedRoute, private parrinService: EspaceParrainService) {
  }
  classElev: any;
  allTravailByNumInscrit: any;
  getAllTravToElve: any;
  numElev: any;
  trimestre: any;
  currentPage: number = 1;
  travail: any;
  
  key: string = 'nom'; //set default
  reverse: boolean = false;
  ngOnInit() {
    this.classElev = this.activateRoute.snapshot.paramMap.get('classe');
    this.numElev = this.activateRoute.snapshot.paramMap.get('numInscrit');
    // this.getTrimestre();
    this.getAllTravail();
  }

  getAllTravail() {
    this.parrinService.getAllTDByNumInscrit(this.numElev, this.trimestre).subscribe((data) => {
      this.allTravailByNumInscrit = data;
      console.log("all travail by elevee", this.allTravailByNumInscrit);
      
    }, error => {
      console.log(error);
    });
  }
  getTravailById(id: any) {
    
    this.parrinService.getTravailById(id).subscribe((travail) => {
      this.travail = travail;
      this.travail.date_envoi = this.datePipe.transform(this.travail.date_envoi, 'yyyy-MM-dd HH:mm');
      this.travail.datefinal = this.datePipe.transform(this.travail.datefinal, 'yyyy-MM-dd HH:mm');     
      console.log(this.travail);
      
    })
  }

  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  downloadFile(filename: string){
    if(filename){
      this.parrinService.downloadFile(filename).subscribe((response)=>{
        console.log(response)
        if(response=== "success"){
          swal({
            position: "center",
            title: "Le fichier " + filename + " est téléchargé avec succés!",
            text:"Veuillez vérifier votre dossier de téléchargement!",
            showConfirmButton: true,
          });
          this.getAllTravail();
        }
      }, error => {
        console.log(error)
        swal({
          position: "center",
          title: "Veuillez réessayer ultérieurement",
          showConfirmButton: true,
          timer: 3500,
        });
        this.getAllTravail();
      });
    }
  }
  
  // getTrimestre() {
  //   this.trimestre = this.parrinService.getTrimestre()
  //     .subscribe(trim => {
  //       this.trimestre = trim;
  //       this.getAllTravail();
  //     });
  // }
  // public download(): void {
  //   const documentCreator = new DocumentCreator();
  //   const doc = documentCreator.create([
  //     experiences,
  //     educations,
  //     skills,
  //     achievements
  //   ]);
  
  //   Packer.toBlob(doc).then(blob => {
  //     console.log(blob);
  //     saveAs(blob, "example.docx");
  //     console.log("Document created successfully");
  //   });
  // }
 
  
}
