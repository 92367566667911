import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { EleveEmploi } from './../../espace-parent/service/eleve-emploi';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { CONFIG } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReleveDesNotesEnsService {

  constructor(private http: HttpClient, private _http: HttpClient) { }
  url11500: string = CONFIG.URL + CONFIG.apiPath + 'eleve/allbyclass';

  url115: string = CONFIG.URL + CONFIG.apiPath + 'eleve/allbyclass';
  getAllEpreuve(): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    const url78 = CONFIG.URL + CONFIG.apiPath + 'epreuve/all';
    let a: any;
    return a = this._http.get(url78, { headers: headers });
  }
  getNotesEleve(anne, classe, trimestre, usename): Observable<EleveEmploi[]> {
    const url = CONFIG.URL + CONFIG.apiPath;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
   console.log(url + `notes/allbyclassebyusernamebytrimestrebyanne?annee=${anne}&classe=${classe}&trimestre=${trimestre}&username=${usename}`);
    return this.http.get<EleveEmploi[]>(url + `notes/allbyclassebyusernamebytrimestrebyanne?annee=${anne}&classe=${classe}&trimestre=${trimestre}&username=${usename}`, {headers: headers} );
  }
  getListEleve(classe: any): Observable<EleveEmploi[]> {
    const url = CONFIG.URL + CONFIG.apiPath;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get<EleveEmploi[]>(url + `eleve/allbyclass/?nomclasse=${classe}`, {headers: headers});
  }

  getNotesEleveFilredEP(usename, classe, trimestre, epreuve): Observable<EleveEmploi[]> {
    const url = CONFIG.URL + CONFIG.apiPath;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    console.log(url + `notes/allbyclassebyusernamebytrimestrebyepreuve?username=${usename}&classe=${classe}&trimestre=${trimestre}&epreuve=${epreuve}`);
    return this.http.get<EleveEmploi[]>(url + `notes/allbyclassebyusernamebytrimestrebyepreuve?username=${usename}&classe=${classe}&trimestre=${trimestre}&epreuve=${epreuve}`, {headers: headers});
  }

  getannescolaire(): Observable<any> {
    this.url11500 =
      CONFIG.URL +
      CONFIG.apiPath +
      'notes/annescolaire';
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this._http
      .get<any>(this.url11500, { headers: headers });
  }

  getAllElevesbySelectedClass(nomclasse: string): Observable<any[]> {
    this.url115 =
      CONFIG.URL +
      CONFIG.apiPath +
      'eleve/allbyclass/' +
      '?nomclasse=' +
      nomclasse;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this._http
      .get<any>(this.url115, { headers: headers });
  }


  noteByClassAndEpreuve(nomclasse, type): Observable<any[]> {
    const urlns = CONFIG.URL + CONFIG.apiPath + 'notes/allbyclassebyepreuve?nomclasse=' + nomclasse + '&type=' + type;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this._http.get<any>(urlns, {headers: headers});
  }

  findAllNotesByClasseAndTrimestre(classeId: number, trimestre: String): Observable<any>{
    const urlns = CONFIG.URL + CONFIG.apiPath + 'notes/allNotesByClasseByTrimestre?classeId=' + classeId + '&trimestre=' + trimestre;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this._http.get<any>(urlns, {headers: headers});
  }

  getAllNotesByEleveClasseTrimestre(eleveId: number, classeId:number, trimestre:string): Observable<any>{
    const urlns = CONFIG.URL + CONFIG.apiPath + 'notes/getAllNotesByEleveClasseTrimestre?classeId=' + classeId +'&eleveId=' + eleveId + '&trimestre=' + trimestre;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this._http.get<any>(urlns, {headers: headers});
  }

  findAllNotesByEleveClasseTrimestreMatiere(eleveId: number, classeId:number, trimestre:string, matiereId: number): Observable<any>{
    const urlns = CONFIG.URL + CONFIG.apiPath + 'notes/allNotesByEleveClasseTrimestreMatiere?classeId=' + classeId +'&eleveId=' + eleveId + '&matiereId=' + matiereId + '&trimestre=' + trimestre;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this._http.get<any>(urlns, {headers: headers});
  }

 
}
