import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { routerTransition } from './../../../app/router.animations';
import { RessourcesElevesService } from './ressources-eleves.service';
import * as decode from 'jwt-decode';
import { LoginService } from 'src/app/login/login/login.service';

@Component({
  selector: 'app-ressources-eleves',
  templateUrl: './ressources-eleves.component.html',
  styleUrls: ['./ressources-eleves.component.scss'],
  animations: [routerTransition()]
})
export class RessourcesElevesComponent implements OnInit {


  constructor(private loginService: LoginService, public router: Router, private ressourcesElevesService: RessourcesElevesService) { }

  username!: any;
  listEleve: any;
  loading: boolean = false;
  user: any;
  ngOnInit() {
    this.getListEleve()
  }

  getbyusername() {
    this.loading = true;
    var decoded = decode(localStorage.getItem("token"));
    this.username = decoded.sub;
    this.loginService.getUserbyusername(this.username).subscribe((res => {
      this.user = res;
      if (this.user.profil == "Enseignant") {
        this.router.navigate(["./mon-emplois"]);
      } else if (this.user.profil == "Directeur") {
        this.router.navigate(["DashboardDirecteur"]);
      } else if (this.user.profil == "Eleve") {
        this.router.navigate(["./espaceEleve"]);
      }

    }))
    this.loading = false;
  }

  getListEleve(): void {
    this.loading = true;
    var decoded = decode(localStorage.getItem("token"));
    this.username = decoded.sub;
    this.ressourcesElevesService.getElevesByParrain(this.username)

      .subscribe(eleves => {
        console.log("thissssssssssssssssssssssss", this.username);
        this.listEleve = eleves;
        console.log("this list elev", eleves);
      });
  }



}
