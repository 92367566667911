import { NgModule } from '@angular/core';
import { NavbarParentComponent } from './navbar-parent/navbar-parent.component';
import { CommonModule } from '@angular/common';
import { NgbModule, NgbCarouselModule, NgbAlertModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { EspaceParrainRoutingModule } from './espace-parrain-routing.module';
import { EspaceParentComponent } from './espace-parent.component';
import { DashboardParrainComponent } from './dashboard-parrain/dashboard-parrain.component';
import { EleveParrainModule } from './eleve-parrain/eleve-parrain.module';
import { FooterBarParrainComponent } from './footer-bar-parrain/footer-bar-parrain.component';
// import { MdCardModule } from '@angular/material';
import { MDBBootstrapModule } from 'angular-bootstrap-md';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { NavBarService } from './navbar-parent/nav-bar.service';
import { ClasseEncoursComponent } from '../layout/mon-emplois/classe-encours/classe-encours.component';
import { MonEmploisModule } from '../layout/mon-emplois/mon-emplois.module';
import { MonEmploisComponent } from '../layout/mon-emplois/mon-emplois.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedPipesModule } from '../shared/pipes/shared-pipes.module';
import { FlatpickrModule } from 'angularx-flatpickr';
import { MatChipsModule, MatPaginatorModule } from '@angular/material';
import { Eleveservice } from '../layout/gestion-eleves/gestion-eleves.service';
import { AsidenavParentComponent } from './asidenav-parent/asidenav-parent.component';
import { EditProfilComponent } from './edit-profil/edit-profil.component';
import { DetailComponent } from './travail-demande/detail/detail.component';
import { CantinePrentComponent } from './cantine-prent/cantine-prent.component';
import { RessourcesElevesComponent } from './ressources-eleves/ressources-eleves.component';
import { RessourcesElevesModule } from './ressources-eleves/ressources-eleves.module';

@NgModule({
    imports: [
        CommonModule,
        EspaceParrainRoutingModule,
        EleveParrainModule,
        MDBBootstrapModule.forRoot(),
        MatSidenavModule,
        MatButtonModule,
        CommonModule,
        NgbCarouselModule.forRoot(),
        NgbAlertModule.forRoot(), NgbDropdownModule.forRoot(),
        NgbModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        SharedPipesModule,
        FlatpickrModule.forRoot(),
        MDBBootstrapModule.forRoot(),
        MatSidenavModule,
        MatButtonModule, MatButtonModule,
        MatChipsModule,
        MatPaginatorModule,
        RessourcesElevesModule

    ],



    providers: [
        NgbModule, NavBarService, Eleveservice,
    ],
    declarations: [
        NavbarParentComponent,
        EspaceParentComponent,
        DashboardParrainComponent,
        FooterBarParrainComponent,
        AsidenavParentComponent,
        EditProfilComponent,
        DetailComponent,
        CantinePrentComponent,
        // RessourcesElevesComponent,

    ]
    , exports: [
        NavbarParentComponent,
        EspaceParentComponent,
        DashboardParrainComponent,
        FooterBarParrainComponent]
})

export class EspaceParentModule { }
