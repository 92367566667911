import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";

import { CONFIG } from "../../../environments/environment";
import { Eleve } from "./eleve";
import { User } from "../gestion-utilisateurs/user";
import * as decode from "jwt-decode";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

@Injectable()
export class Eleveservice {
  constructor(private _http: HttpClient) {}
  url5: string = CONFIG.URL + CONFIG.apiPath + "eleve/remove";
  url116: string;

  // Add  user
  url2: string = CONFIG.URL + CONFIG.apiPath;

  url: string = CONFIG.URL + CONFIG.apiPath + "eleve/all";

  url10: string = CONFIG.URL + CONFIG.apiPath + "eleve/allbyclass";
  url100: string = CONFIG.URL + CONFIG.apiPath + "eleve/allbyclassnow";
  url110: string = CONFIG.URL + CONFIG.apiPath + "eleve/allbyclassencours";
  url111: string = CONFIG.URL + CONFIG.apiPath + "eleve/bynumInscription/";

  url112: string = CONFIG.URL + "api/penalites/getEleveByPenality/";

  url22: string = CONFIG.URL + CONFIG.apiPath + "eleve/addelevewithparrain";

  url113: string =
    CONFIG.URL + CONFIG.apiPath + "eleve/parraindetailsforeleve/";

  urlImg: string = CONFIG.URL + "image/imagebyNumInscription";

  url114: string = CONFIG.URL + CONFIG.apiPath + "eleve/allbyniveau";
  url115: string = CONFIG.URL + CONFIG.apiPath + "eleve/allbyclass";

  url33: string = CONFIG.URL + CONFIG.apiPath + "eleve/elevewithclassnull";
  deleteEleve(nomEleve: any): any {
    const token = localStorage.getItem("token");
    const headers = new HttpHeaders().set("Authorization", "Bearer " + token);
    return this._http.post(this.url5, nomEleve, { headers: headers });
  }

  archiveappreciation(id: any): any {
    const decoded = decode(localStorage.getItem("token"));
    const username = decoded.sub;
    const token = localStorage.getItem("token");
    const headers = new HttpHeaders().set("Authorization", "Bearer " + token);
    const url =
      CONFIG.URL +
      CONFIG.apiPath +
      "appreciation/archiversapreciation" +
      "?username=" +
      username;
    console.log(url);
    return this._http.post(url, id, { headers: headers });
  }

  getAll(): Observable<any[]> {
    const token = localStorage.getItem("token");
    const headers = new HttpHeaders().set("Authorization", "Bearer " + token);
    const decoded = decode(localStorage.getItem("token"));
    const username = decoded.sub;
    const url =
      CONFIG.URL + CONFIG.apiPath + "appreciation/appreciationallbyarchiver";
    return this._http.get<any>(url, { headers: headers });
  }

  addEleve(
    nom: string,
    prenom: string,
    niveauEtude: string,
    date_naissance: Date,
    sexe: string,
    etat: string,
    cin: number,

    remarque: string,
    username: string,
    email: string,
    password: string
  ): Observable<any[]> {
    const decoded = decode(localStorage.getItem("token"));
    const usernames = decoded.sub;
    const token = localStorage.getItem("token");
    const headers = new HttpHeaders().set("Authorization", "Bearer " + token);
    return this._http.post<any>(
      this.url2 + "eleve/add?creatorName=" + usernames,
      {
        nom: nom,
        prenom: prenom,
        niveauEtude: niveauEtude,
        date_naissance: date_naissance,
        sexe: sexe,
        etat: etat,
        cin: cin,

        remarque: remarque,
        username: email,
        password: password,
      } || null,
      { headers: headers }
    );
  }

  getAllNiveaux() {
    const decoded = decode(localStorage.getItem("token"));
    const username = decoded.sub;
    const token = localStorage.getItem("token");
    const headers = new HttpHeaders().set("Authorization", "Bearer " + token);
    const url =
      CONFIG.URL +
      CONFIG.apiPath +
      "niveau/getallbyetablissement" +
      "?username=" +
      username;
    return this._http.get(url, { headers: headers });
  }

  getAllEleves(): Observable<Eleve[]> {
    const decoded = decode(localStorage.getItem("token"));
    const username = decoded.sub;
    const token = localStorage.getItem("token");
    const headers = new HttpHeaders().set("Authorization", "Bearer " + token);
       const url =CONFIG.URL + CONFIG.apiPath +"eleve/all"

    return this._http.get<Eleve[]>(url, { headers: headers });
  }

  UpdateEleve(u: Eleve): Observable<any[]> {
    const decoded = decode(localStorage.getItem("token"));
    const username = decoded.sub;
    const url = CONFIG.URL + CONFIG.apiPath + "eleve/updateEleve";

    const token = localStorage.getItem("token");
    const headers = new HttpHeaders().set("Authorization", "Bearer " + token);

    return this._http.put<any>(url, u, { headers: headers });
  }

  getAllElevesbyclasse(nomclasse: any): Observable<any[]> {
    this.url10 =
      CONFIG.URL +
      CONFIG.apiPath +
      "eleve/allbyclass/" +
      "?nomclasse=" +
      nomclasse;
    const token = localStorage.getItem("token");
    const headers = new HttpHeaders().set("Authorization", "Bearer " + token);
    return this._http.get<any>(this.url10, { headers: headers });
  }
  getAllElevesbyclassenow(username: any): Observable<any[]> {
    const token = localStorage.getItem("token");
    const headers = new HttpHeaders().set("Authorization", "Bearer " + token);
    this.url100 =
      CONFIG.URL +
      CONFIG.apiPath +
      "eleve/allbyclassnow/" +
      "?username=" +
      username;
    return this._http.get<any>(this.url100, { headers: headers });
  }

  archiver(id: any): any {
    const decoded = decode(localStorage.getItem("token"));
    const username = decoded.sub;
    const url =
      CONFIG.URL +
      CONFIG.apiPath +
      "eleve/archiveeleves" +
      "?username=" +
      username;
    const token = localStorage.getItem("token");
    const headers = new HttpHeaders().set("Authorization", "Bearer " + token);
    return this._http.post(url, id, { headers: headers });
  }

  getAllElevesbyclasseEncours(id: any): Observable<any[]> {
    const token = localStorage.getItem("token");
    const headers = new HttpHeaders().set("Authorization", "Bearer " + token);
    const decoded = decode(localStorage.getItem("token"));
    const username = decoded.sub;
    this.url110 =
      CONFIG.URL +
      CONFIG.apiPath +
      "eleve/allbyclassencours/"+
      "?id=" +
      id +
      "&username=" +
      username;

    return this._http.get<any>(this.url110, { headers: headers });
  }
  getelevebynumInscription(numInscription: any): Observable<Eleve> {
    this.url111 =
      CONFIG.URL +
      CONFIG.apiPath +
      "eleve/bynumInscription/" +
      "?numInscription=" +
      numInscription;
    const token = localStorage.getItem("token");
    const headers = new HttpHeaders().set("Authorization", "Bearer " + token);
    return this._http.get<any>(this.url111, { headers: headers });
  }
  getelevebyPenalite(numInscription: any): any {
    this.url112 =
      CONFIG.URL +
      "api/penalites/getEleveByPenality/" +
      "?numInscription=" +
      numInscription;
    const token = localStorage.getItem("token");
    const headers = new HttpHeaders().set("Authorization", "Bearer " + token);
    return this._http.get(this.url112, { headers: headers });
  }

  addelevewithparrain(parent: User, eleve: Eleve): Observable<any> {
    const decoded = decode(localStorage.getItem("token"));
    const username = decoded.sub;
    eleve.username = username;

    const token = localStorage.getItem("token");
    const headers = new HttpHeaders().set("Authorization", "Bearer " + token);
    const url = CONFIG.URL + CONFIG.apiPath + "eleve/addelevewithparrain";
    return this._http.post(url, { eleve: eleve, parent: parent } || null, {
      headers: headers,
    });
  }
  getparrainbyEleve(numInscription: any): any {
    this.url113 =
      CONFIG.URL +
      CONFIG.apiPath +
      "eleve/parraindetailsforeleve/" +
      "?numinscription=" +
      numInscription;
    const token = localStorage.getItem("token");
    const headers = new HttpHeaders().set("Authorization", "Bearer " + token);
    return this._http.get(this.url113, { headers: headers });
  }

  getAppreciationCount(numInscription: any): any {
    this.url113 =
      CONFIG.URL +
      CONFIG.apiPath +
      "appreciation/countappreciationprof/" +
      "?numinscri=" +
      numInscription;
    const token = localStorage.getItem("token");
    const headers = new HttpHeaders().set("Authorization", "Bearer " + token);
    return this._http.get(this.url113, { headers: headers });
  }

  getAppreciationlist(numInscription: any): any {
    this.url113 =
      CONFIG.URL +
      CONFIG.apiPath +
      "appreciation/allbynuminscription/" +
      "?numinscri=" +
      numInscription;
    const token = localStorage.getItem("token");
    const headers = new HttpHeaders().set("Authorization", "Bearer " + token);
    return this._http.get(this.url113, { headers: headers });
  }
  getImgEleve(numInscription: any): any {
    let params = new HttpParams();
    params = params.append("numinscription", numInscription);
    this.urlImg = CONFIG.URL +  "image/imagebyNumInscriptionss";
    const token = localStorage.getItem("token");
    const headers = new HttpHeaders().set("Authorization", "Bearer " + token);
    return this._http.get(this.urlImg, { headers: headers, params: params });
  }

  getAllElevesbyNiveau(niveau: any): Observable<any[]> {
    console.log("esmliclasse estfi servce " + niveau);
    this.url114 =
      CONFIG.URL + CONFIG.apiPath + "eleve/allbyniveau/" + "?niveau=" + niveau;
    const token = localStorage.getItem("token");
    const headers = new HttpHeaders().set("Authorization", "Bearer " + token);
    return this._http.get<any>(this.url114, { headers: headers });
  }

  getAllElevesbySelectedClass(nomclasse: string): Observable<any[]> {
    this.url115 =
      CONFIG.URL +
      CONFIG.apiPath +
      "eleve/allbyclass/" +
      "?nomclasse=" +
      nomclasse;
    const token = localStorage.getItem("token");
    const headers = new HttpHeaders().set("Authorization", "Bearer " + token);
    return this._http.get<any>(this.url115, { headers: headers });
  }

  getAllElevesNonAffetedToclasse(niveau): Observable<Eleve[]> {
    const decoded = decode(localStorage.getItem("token"));
    const username = decoded.sub;
    const token = localStorage.getItem("token");
    const headers = new HttpHeaders().set("Authorization", "Bearer " + token);
    this.url10 =
      CONFIG.URL +
      CONFIG.apiPath +
      "eleve/allbyniveauwithclassnull/" +
      "?niveau=" +
      niveau;

    return this._http.get<any>(this.url10, { headers: headers });
  }

  counteleve(): Observable<Eleve[]> {
    const decoded = decode(localStorage.getItem("token"));
    const username = decoded.sub;
    const url =
      CONFIG.URL +
      CONFIG.apiPath +
      "eleve/counteleve" +
      "?username=" +
      username;
    const token = localStorage.getItem("token");
    const headers = new HttpHeaders().set("Authorization", "Bearer " + token);
    return this._http.get<Eleve[]>(url, { headers: headers });
  }

  countfille(): Observable<Eleve[]> {
    const decoded = decode(localStorage.getItem("token"));
    const username = decoded.sub;
    const token = localStorage.getItem("token");
    const headers = new HttpHeaders().set("Authorization", "Bearer " + token);
    const url =
      CONFIG.URL +
      CONFIG.apiPath +
      "eleve/countfille" +
      "?username=" +
      username;
    return this._http.get<Eleve[]>(url, { headers: headers });
  }

  countgarcon(): Observable<Eleve[]> {
    const decoded = decode(localStorage.getItem("token"));
    const username = decoded.sub;
    const url =
      CONFIG.URL +
      CONFIG.apiPath +
      "eleve/countgarcon" +
      "?username=" +
      username;
    const token = localStorage.getItem("token");
    const headers = new HttpHeaders().set("Authorization", "Bearer " + token);
    return this._http.get<Eleve[]>(url, { headers: headers });
  }
}
