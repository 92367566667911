import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import * as decode from 'jwt-decode';
import { LoginService } from 'src/app/login/login/login.service';
import { routerTransition } from 'src/app/router.animations';
import { SaisieDesNotesService } from './../saisie-des-notes/saisie-des-notes.service';
import { ReleveDesNotesEnsService } from './releve-des-notes-ens.service';
import { FormuleNoteService } from '../formule-note/formule-note.service';


@Component({
  selector: 'app-releve-des-notes-ens',
  templateUrl: './releve-des-notes-ens.component.html',
  animations: [routerTransition()],

  styleUrls: ['./releve-des-notes-ens.component.scss']
})
export class ReleveDesNotesEnsComponent implements OnInit {
  classformadd: FormGroup;
  epvreuveForm: FormGroup;
  user: any;
  Ischoise: any;
  username: any;
  classes: any[];
  sortedClasse: any[];
  sendedEpeuve: any;
  nomTrimestre: any;
  classeShosed: any;
  listNoteEleve: any[] = [];
  matiere: any;
  IsChoise = false;
  listleve: any;
j:0;
  classeChosed: any;
  allEleves: any[] = [];
  nomTrimestreChosed: any;
  listNoteEleveNew: any;
  moyenneAllEleve: any;
  annescolaire:any;
  allepreuve:any [];
  loading :boolean =false ;
  notesByClasseAndEpreuve:any [];
  epreuves: any[];
  classByTeacher:any[];
  matiereByProf: any;
  selectedClasse:any;
  epreuve:any
  constructor(
    private formBuilder: FormBuilder,
    private saisieDesNotesService: SaisieDesNotesService,
    private loginService: LoginService,
    private releveDesNotesEnsService: ReleveDesNotesEnsService,
    private formuleNoteService: FormuleNoteService,
    public router: Router,
    
  ) {
    this.classformadd = this.formBuilder.group({
      'classe': ['', Validators.required],
      'nomTrimestre': ['', Validators.required],
    });
  }


  ngOnInit() {
    this.getUserbyusername();
    this.getAllClassesBySelectedProf();
    
    
    this.getbyusername();
    
  }
 
  getUserbyusername() {
    if (localStorage.getItem('token') != null) {
      var decoded = decode(localStorage.getItem('token'));
      this.username = decoded.sub;
      this.saisieDesNotesService.getUserbyusername(this.username)
        .subscribe((data) => {
          this.matiereByProf= data.matiere;
           console.log("thiiiiiiiiiiiiiis matiere by prof",this.matiereByProf);
          
        }, error => {
          console.log(error);
        });
    }
  }
  getbyusername(){
    this.loading=true ;
    var decoded = decode(localStorage.getItem("token"));
    this.username = decoded.sub;
    this.loginService.getUserbyusername(this.username).subscribe((res=>
      {
        this.user = res;
                  if (this.user.profil == "Parrain") {
                    this.router.navigate(["/espaceParent"]);
                  } else if (this.user.profil == "Directeur") {
                    this.router.navigate(["DashboardDirecteur"]);
                  }
      }))
      console.log(this.user)
      this.loading=false;
  }
  getannescolaire(){
  this.releveDesNotesEnsService.getannescolaire().subscribe(data=>{
  this.annescolaire = data ;
  console.log("**************/////////////******* " + this.annescolaire)
  }, error => {
    console.log(error);
  });

  }
 

  getAllClassesBySelectedProf() {
    if (localStorage.getItem('token') != null) {
      var decoded = decode(localStorage.getItem('token'));
      this.username = decoded.sub;
      let ens = this.username.includes('ens');
      console.log(ens);
      this.formuleNoteService.findClassesByTeacher(this.username).subscribe(
        (data) => {
          this.classByTeacher = data.sort(function(a,b){
           return a.id - b.id;
          })
          console.log("class by teacher ", this.classByTeacher);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  getAllElevesbySelectedClassHAHA(classe) {
    console.log(classe);
    
    this.listNoteEleve!.length = 0;
    this.releveDesNotesEnsService.getAllElevesbySelectedClass(classe)
      .subscribe((data) => {
        
        this.allEleves = data;       
        console.log("eleves par classe", this.allEleves);
     
      },
      error => {
        console.log("error eleve by classe ",error);
        
      });
  }

  afficherReleveNotes(classe, trimestre){
    console.log(classe, trimestre);
    
    this.classeChosed = this.classByTeacher.find(cls=>cls.id == classe);
    this.listNoteEleve.length = 0;
    
    this.releveDesNotesEnsService.getAllElevesbySelectedClass(this.classeChosed.nomclasse)
      .subscribe((data) => {
      this.allEleves = data;           
      console.log("eleves par classe", this.allEleves);
      this.allEleves.map((elv)=>(
        console.log(elv.id, classe, trimestre, this.matiereByProf.idmatiere),
        
        this.releveDesNotesEnsService.findAllNotesByEleveClasseTrimestreMatiere(elv.id, classe, trimestre, this.matiereByProf.idmatiere).subscribe((data)=>{
          console.log("data",data);
          
          if(data.length>0){
            let sortedData = data.sort(function (a, b) {
              const nameA = a.epreuve.type.toUpperCase(); // ignore upper and lowercase
              const nameB = b.epreuve.type.toUpperCase(); // ignore upper and lowercase
              
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
            
              // names must be equal
              return 0;
            })  
            this.IsChoise=true;
            this.listNoteEleve.push(sortedData);
          }
          console.log("liste des notes ", this.listNoteEleve);
        })
      ))
    
      },
      error => {
        console.log("error eleve by classe ",error);
        
      });
  
  }
 
 


  getAllEpreuveByClassAndMatiere(classeId, matiereId) {
    console.log("selected class ",classeId,"selected matiere ", matiereId);
    
    this.formuleNoteService
      .getAllEpreuveByClassAndMatiere(classeId, matiereId)
      .subscribe(
        (data) => {
          this.selectedClasse = this.classByTeacher.find(cls=> cls.id == classeId);
          console.log("epreuve by class & matiere ", data);
          this.epreuves = data.sort(function (a, b) {
            const nameA = a[6].toUpperCase(); // ignore upper and lowercase
            const nameB = b[6].toUpperCase(); // ignore upper and lowercase
            console.log(nameA, nameB);
            
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
          
            // names must be equal
            return 0;
          })  
          
        },
        (error) => {
          console.log(error);
        }
      );
  }


}
