
import { ActivatedRoute } from '@angular/router';
import { RessourcesElevesService } from '../ressources-eleves.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { EspaceParrainService } from '../../service/espace-parrain.service';
import swal from 'sweetalert2';
@Component({
  changeDetection: ChangeDetectionStrategy.Default,
  selector: 'app-ressources-detail',
  templateUrl: './ressources-detail.component.html',
  styleUrls: ['./ressources-detail.component.scss']
})
export class RessourcesDetailComponent implements OnInit {
  key: string = 'nom'; //set default
  reverse: boolean = false;
  classEleve: any;
  numInscrit: any;
  documentByNumInscrit: any;
  currentPage: number = 1;
  enabled: boolean = false;
  eleveName: string;
  constructor(private parrinService: EspaceParrainService,private activateRoute: ActivatedRoute, private ressourcesElevesService: RessourcesElevesService) { }

  ngOnInit() {
    this.classEleve = this.activateRoute.snapshot.paramMap.get('classe');
    this.numInscrit = this.activateRoute.snapshot.paramMap.get('numInscrit');
    this.getAllDocumentByNumInscrit();
    // this.cdr.detectChanges();
  }

  getAllDocumentByNumInscrit() {
    this.ressourcesElevesService.getAllDocsByNumInscri(this.numInscrit).subscribe((data) => {
      this.documentByNumInscrit = data;

      console.log("documents by num inscriiiiit ", this.documentByNumInscrit)
      console.log("num inscriiiiiit ", this.numInscrit)
    }, error => {
      console.log(error);
    })
  }
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  downloadFile(filename: string){
    if(filename){
      this.ressourcesElevesService.downloadRessource(filename).subscribe((response)=>{
        console.log(response)
        if(response=== "success"){
          swal({
            position: "center",
            title: "Le fichier " + filename + " est téléchargé avec succés!",
            text:"Veuillez vérifier votre dossier de téléchargement!",
            showConfirmButton: true,
          });
          this.getAllDocumentByNumInscrit();
        }
      }, error => {
        console.log(error)
        swal({
          position: "center",
          title: "Veuillez réessayer ultérieurement",
          showConfirmButton: true,
          timer: 3500,
        });
        this.getAllDocumentByNumInscrit();
      });
    }
  }

}
