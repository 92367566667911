import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {HttpClient, HttpHeaders} from '@angular/common/http';
//import { CONFIG } from 'src/app/config';
import { CONFIG } from '../../../environments/environment';
import { catchError, tap } from 'rxjs/operators';





@Injectable()
export class NavBarService {

  url: string = CONFIG.URL + CONFIG.apiPath + 'note/addnote';
  url1: string = CONFIG.URL + CONFIG.apiPath + 'note/allnotes';
  urlservice = CONFIG.URL + CONFIG.apiPath + 'socket/getnotifparent?numinscri=';


  constructor(private _http: HttpClient, private http: HttpClient) {

  }


  getparent(numinscri: any): Observable<any[]> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this._http.get<any>(this.urlservice + numinscri, { headers: headers });
  }
















}
