import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout.component';
import { AuthGuard } from '../shared/guard/auth.guard';
import { ReleveDesNotesEnsComponent } from '././releve-des-notes-ens/releve-des-notes-ens.component';
import { ReleveMoyennesEnseignantComponent } from './releve-moyennes-enseignant/releve-moyennes-enseignant.component';


const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      //  {path: 'gestion-avertissement', loadChildren: './gestion-avertissement/gestion-avertissement.module#GestionAvertissementModule'},

      { path: 'gestion-retenu', loadChildren: './gestion-retenu/gestion-retenu.module#GestionRetenuModule' },
      { path: 'gestion-appreciation', loadChildren: './gestion-appreciation/gestion-appreciation.module#GestionAppreciationModule' },

      { path: 'gestion-exclus', loadChildren: './gestion-exclus/gestion-exclus.module#GestionExclusModule' },
      { path: 'emplois-update', loadChildren: './emplois-update/emplois-update.module#EmploisUpdateModule' },
      { path: 'gestion-parent', loadChildren: './gestion-parrain/gestion-parrain.module#GestionParrainModule' },
      { path: 'mon-emplois', loadChildren: './mon-emplois/mon-emplois.module#MonEmploisModule' },
      { path: 'remarques', loadChildren: './notes/notes.module#NotesModule' },
      { path: 'profile', loadChildren: './profile/profile.module#ProfileModule' },

      { path: 'gestion-emplois', loadChildren: './gestion-emplois/gestion-emplois.module#GestionEmploisModule' },

      { path: 'gestion-message', loadChildren: './gestion-message/gestion-message.module#GestionMessageModule' },

      { path: 'gestion-classes', loadChildren: './gestion-classes/gestion-classes.module#GestionClassesModule' },
      { path: 'upload-excel', loadChildren: './upload/upload.module#UploadModule' },

      { path: 'gestion-semaine', loadChildren: './gestion-semaine/gestion-semain.module#GestionSemaineModule' },
      { path: 'gestion-eleves', loadChildren: './gestion-eleves/gestion-eleves.module#GestionElevesModule' },

      { path: 'gestion-matieres', loadChildren: './gestion-matieres/gestion-matieres.module#GestionMatiereModule' },

      { path: 'gestion-epreuves', loadChildren: './gestion-epreuves/gestion-epreuves.module#GestionEpreuvesModule' },
      { path: 'gestion-formule-matiere', loadChildren: './gestion-formule-matiere/gestion-formule-matiere.module#GestionFormuleMatiereModule' },
      { path: 'gestion-formule-trimestre', loadChildren: './gestion-formule-trimestre/gestion-formule-trimestre.module#GestionFormuleTrimestreModule' },


      { path: 'gestion-salles', loadChildren: './gestion-salles/gestion-salles.module#GestionSallesModule' },

      { path: 'Dashboard', loadChildren: './rapport-statistique/dashboard/dashboard-statistique.module#DashboardStatistiqueModule' },
      { path: 'DashboardDirecteur', loadChildren: './dashboard-directeur/dashboard-directeur.module#DashboarddirecteurModule' },

      { path: 'historique', loadChildren: './gestion-historique/gestion-historique.module#GestionHistoriqueModule' },
      { path: 'archive', loadChildren: './gestion-archive/gestion-archive.module#GestionArchiveModule' },
      { path: 'gestion-roles', loadChildren: './gestion-roles/gestion-roles.module#GestionRolesModule' },
      {
        path: 'gestion-utilisateurs',
        loadChildren: './gestion-utilisateurs/gestion-utilisateurs.module#GestionUtilisateursModule'
      },
      {
        path: 'gestion-enseignant',
        loadChildren: './gestion-enseignant/gestion-enseignant.module#GestionEnseignantModule'
      },
      {
        path: 'gestion-avertissement',
        loadChildren: './gestion-avertissement/gestion-avertissement.module#GestionAvertissementModule'
      },

      {
        path: 'gestion-nombreheure',
        loadChildren: './gestion-nombreheure/gestion-nombreheure.module#GestionNombreHeureModule'
      },

      { path: 'injection-emplois', loadChildren: './injection-emplois/InjectionEmplois.module#InjectionEmploisModule' },
      {
        path: 'gestion-niveau',
        loadChildren: './gestion-niveau/gestion-niveau.module#GestionNiveauModule'
      },
      { path: 'gestion-cantines', loadChildren: './gestion-cantines/gestion-cantines.module#GestionCantinesModule' },
      { path: 'gestion-travaux', loadChildren: './gestion-travaux/gestion-travaux.module#GestionTravauxModule' },
      { path: 'gestion-violence', loadChildren: './gestion-violence/gestion-violence.module#GestionViolenceModule' },
      { path: 'cahier-classe', loadChildren: './cahier-classe/cahier-classe.module#CahierClasseModule' },
      { path: 'travail-demandes', loadChildren: './travail-demandes/travail-demandes.module#TravailDemandesModule' },
      { path: 'saisie-des-notes', loadChildren: './saisie-des-notes/saisie-des-notes.module#SaisieDesNotesModule' },
      { path: 'semaine-generer', loadChildren: './semaine-generer/semaine-generer.module#SemaineGenererModule' },

      { path: 'ressources-eleve', loadChildren: './ressources-eleve/ressources-eleve.module#RessourcesEleveModule' },
      {
        path: 'gestion-boite-lettre',
        loadChildren: './gestion-boite-lettre/gestion-boite-lettre.module#GestionBoiteLettreModule'
      },
      {
        path: 'add-boite-lettre',
        loadChildren: './add-boite-lettre/add-boite-lettre.module#AddBoiteLettreModule'
      },
      {
        path: 'envoyer-boite-lettre',
        loadChildren: './envoyer-boite-lettre/envoyer-boite-lettre.module#EnvoyerBoiteLettreModule'
      },
      {
        path: 'archiver-boite-lettre',
        loadChildren: './archvier-boite-lettre/archvier-boite-lettre.module#ArchiverBoiteLettreModule'
      },
      {
        path: 'brouillons-boite-lettre',
        loadChildren: './brouillons-boite-lettre/brouillons-boite-lettre.module#BrouillonsBoiteLettreModule'
      },
      {
        path: 'detail-boite-lettre',
        loadChildren: './detail-boite-lettre/detail-boite-lettre.module#DetailBoiteLettreModule'
      },

      { path: 'gestion-affecte-eleve', loadChildren: './gestion-affecte-eleve/gestion-affecte-eleve.module#GestionAffecteEleveModule' },
      { path: 'releve-notes-eleve-ens', component: ReleveDesNotesEnsComponent },
      { path: 'releve-moyennes-enseignant', component: ReleveMoyennesEnseignantComponent },
      { path: 'reservation-materiel', loadChildren: './reservation-materiel/reservation-materiel.module#ReservationMaterielModule' },
      { path: 'gestion-notes', loadChildren: './formule-note/formule-note.module#FormuleNoteModule' },
      { path: 'modifier-notes', loadChildren: './modifiernotes/modifiernotes.module#Modifiernotes' },

      { path: 'list-casier', loadChildren: './casier-num/list-casier/list-casier.module#ListCasierModule' },

      { path: 'list-formule', loadChildren: './gestion-formule/gestion-formule/gestion-formule.module#GestionFormuleModule' },

      { path: 'gestion-formule', loadChildren: './gestion-formule/gestion-formule/gestion-formule.module#GestionFormuleModule' },
      //{ path: 'gestion-formule-trimestre', loadChildren: './gestion-formule/gestion-formule/gestion-formule.module#GestionFormuleModule' },

    ],

  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutRoutingModule {
}
