import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { EleveEmploi } from './../../espace-parent/service/eleve-emploi';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { CONFIG } from '../../../environments/environment';
import * as decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class ReleveDesNotesService {
  constructor(private http: HttpClient, private _http: HttpClient) { }
  getNotesEleve(Numinsc, nomTrimestreChosed, ClassEleve): Observable<EleveEmploi[]> {
    const url = CONFIG.URL + CONFIG.apiPath;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get<EleveEmploi[]>(url + `notes/allnotebytrimestrebyclassebyeleve?classe=${ClassEleve}&trimestre=${nomTrimestreChosed}&eleve=${Numinsc}`, {headers: headers});
  }


}
