import { Component, OnInit, Input } from '@angular/core';
import * as decode from 'jwt-decode';
// import { CONFIG } from 'src/app/config';
import { CONFIG } from '../../../environments/environment';
import { Message } from 'src/app/model/messagesecoket';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { NavbarService } from 'angular-bootstrap-md';
import { NavBarService } from './nav-bar.service';
import { Messageparent } from 'src/app/model/messageparent';
import { LoginService } from 'src/app/login/login/login.service';
import { User } from 'src/app/layout/gestion-utilisateurs/user';
import { DomSanitizer } from '@angular/platform-browser';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import swal from 'sweetalert2';
import { AngularFirestore } from '@angular/fire/firestore';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-navbar-parent',
  templateUrl: './navbar-parent.component.html',
  styleUrls: ['./navbar-parent.component.scss'],
  providers: [DatePipe]

})
export class NavbarParentComponent implements OnInit {

  constructor(public navbarservice: NavBarService, private datePipe: DatePipe, private db: AngularFirestore, private loginService: LoginService, private sanitizer: DomSanitizer, private httpClient: HttpClient) {
    this.daynowDate = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');

  }
  count = 0;
  countretenu = 0;
 // private serverUrl = CONFIG.URLsocketnote + 'socketjs'

  isLoaded = true;
  isCustomSocketOpened = false;
  private stompClient;
  messagesretenu: Messageparent[] = [];
  messages: Messageparent[] = [];
  messagesappreciation: Messageparent[] = [];
  countapreciation = 0;
  daynowDate: string;
  myDate = new Date();

  username;
  parent: any;
  profil: any;
  nomuser: any;
  prenomuser: any;
  user: User;
  imgSin: any;
  thumbnail: any;
  thumbnailTest: any;
  numInscription: any;


  selectedFile: File;
  message: string;
  listNotif: any[];
  tabRes = [];
  listNotifabscen: any[];

listNotifPArent: any[] = [];
data: any ;
listEleve: any[];
  ngOnInit() {
    this.getnotifabscent();
    this.getnotifparent();
    if (localStorage.getItem('token') != null) {
      const decoded = decode(localStorage.getItem('token'));
      this.username = decoded.sub;
      console.log('usersocket' + this.username);

    }

   // this.initializeWebSocketConnection();
    this.getProfileImg();
    if (localStorage.getItem('token') != null) {
      const decoded = decode(localStorage.getItem('token'));
      this.username = decoded.sub;
      this.getUserbyusername();
    }


    $(document).ready(() => {

      $('.close-sidebar-btn').click(function () {
        const classToSwitch = $(this).attr('data-class');
        const containerElement = '.app-container';
        $(containerElement).toggleClass(classToSwitch);
        const closeBtn = $(this);
        if (closeBtn.hasClass('is-active')) {
          closeBtn.removeClass('is-active');
        } else {
          closeBtn.addClass('is-active');
        }
      });
    });

  }


  getUserbyusername() {

    const decoded = decode(localStorage.getItem('token'));
    this.username = decoded.sub;
    this.loginService.getUserbyusername(this.username)

      .subscribe((user) => {
        this.user = user;
        this.profil = user.profil;
        this.nomuser = this.user.nom;
        this.prenomuser = this.user.prenom;

        if (this.profil == 'Parrain') {

        }
        if (this.profil == 'Enseignant') {

        } else {

        }

      }, error => {
        console.log(error);
      });
  }

  getProfileImg() {
    this.loginService.getAllimage()
      .subscribe((imagetable) => {
        const retrievedImage = 'data:image/jpeg;base64,' + imagetable;
        this.thumbnail = this.sanitizer.bypassSecurityTrustUrl(retrievedImage);
      }, error => {
        this.thumbnail = '';
        this.thumbnailTest = true;
        // console.log("error" + error);
      });
  }


  getAllimageSign() {
    this.loginService.getAllimageSign()
      .subscribe((imagetable) => {
        const retrievedImage = 'data:image/jpeg;base64,' + imagetable;
        this.imgSin = this.sanitizer.bypassSecurityTrustUrl(retrievedImage);
      }, error => {
        // console.log(error);
        this.imgSin = '';
      });
  }


  public onFileChangedSignature(event) {
    this.selectedFile = event.target.files[0];
    console.log(this.selectedFile);
  }

  public onUploadSignature(u) {
    const uploadImageData = new FormData();
    uploadImageData.append(
      'imageFile',
      this.selectedFile,
      this.selectedFile.name
    );
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
   this.httpClient
      .post(CONFIG.URL + 'signature/upload' +
        '?username=' +
        this.username,
        uploadImageData,
        {
          observe: 'response',
          reportProgress: true,
          headers: headers
        }
      )
      .subscribe(
        response => {
          if (response.status === 200) {
            swal({
              position: 'center',
              title: 'photo ajoutée avec succès.',
              showConfirmButton: true
            });

          } else {
            swal({
              position: 'center',
              type: 'warning',
              title: 'Photo ajouté .',
              showConfirmButton: true
            });
            /* this.message = "Image not uploaded successfully";*/
          }
        }
        /*event => {
          if (event.type === HttpEventType.UploadProgress) {
            const percentDone = Math.round((100 * event.loaded) / event.total);
            console.log(event);
          }
        }*/
      );

  }

  public onFileChanged(event) {
    console.log(CONFIG.URL);
    // Select File
    this.selectedFile = event.target.files[0];
  }
getnotifparent() {
this.loginService.getAllbyparent().subscribe(res => {

this.listNotifPArent = res.map(x => x.numInscription);

for (let index = 0; index < this.listNotifPArent.length; index++) {
  const element = this.listNotifPArent[index];
  console.log('**///' + element);
  this.db.collection('abscencenotif', ref => ref
  .where('numInscription', '==', element)
     .where('day', '==', this.daynowDate.split('-').reverse().join('-')))
     .valueChanges().subscribe(res => {

      this.listNotif = res;
      console.log('------------------' + res);
      if (this.listNotif.length > 0) {
        this.tabRes = this.listNotif;
        this.countretenu = this.tabRes.length;
        this.tabRes.sort((a, b) => (a.time < b.time) ? 1 : -1);
      }

    });
}


});

  }
  getnotifabscent() {
    this.loginService.getAllbyparent().subscribe(res => {
    this.listNotifPArent = res.map(x => x.numInscription);
    for (let index = 0; index < this.listNotifPArent.length; index++) {
      const element = this.listNotifPArent[index];
      this.db.collection('abscencenotif', ref => ref
      .where('numInscription', '==', element)
         .where('day', '==', this.daynowDate.split('-').reverse().join('-')))
         .valueChanges().subscribe(res => {
          this.listNotifabscen = res;
         // let NumberOfExcpeled =  this.listNotifabscen.filter((element)=>element.motif == "Abscence" );

          this.countretenu =   res.length;
        });
    }
    });
      }
  onUpload() {
    // FormData API provides methods and properties to allow us easily prepare form data to be sent with POST HTTP requests.
    const uploadImageData = new FormData();
    uploadImageData.append('imageFile', this.selectedFile, this.selectedFile.name);
    const decoded = decode(localStorage.getItem('token'));
    const username = decoded.sub;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    // Make a call to the Spring Boot Application to save the image
    this.httpClient.post(CONFIG.URL + 'image/upload' + '?username=' + username, uploadImageData, { observe: 'response' , headers: headers})
      .subscribe((response) => {
        if (response.status === 200) {
          swal({
            position: 'center',
            title: 'photo ajoutée avec succès.',
            showConfirmButton: true
          });
          this.getProfileImg();
        } else {
          this.message = 'Image not uploaded successfully';
        }
      }
      );
  }

  displayCounter(count) {
    console.log(count);
  }



  onLoggedout() {
    localStorage.removeItem('token');
  }


  initcount() {
    this.count = 0;
  }

  // initializeWebSocketConnection() {
  //   let ws = new SockJS(this.serverUrl);
  //   this.stompClient = Stomp.over(ws);
  //   let that = this;
  //   this.stompClient.connect({}, function (frame) {
  //     that.isLoaded = true;
  //     that.openGlobalSocket()
  //   });
  // }

  openGlobalSocket() {
    this.stompClient.subscribe('/socketjs-publisher', (message) => {
      //  this.handleResult(message);




      this.handleResultretenu(message);
    });
  }
  initapreciation() {
    this.countapreciation = 0;
  }


  handleResultappreciation(messageappre) {
    if (messageappre.body) {
      const messageResult: Messageparent = JSON.parse(messageappre.body);
      console.log(messageResult);
      this.messagesappreciation.push(messageResult);
      console.log(this.messagesappreciation);
    }
  }

  handleResultretenu(messageretenu) {
    if (messageretenu.body) {
      const messageResult: Messageparent = JSON.parse(messageretenu.body);
      if (messageResult.toId == 'retenu') {
        this.countretenu++;
        this.messagesretenu.push(messageResult);
      }
      if (messageResult.toId == 'exclus') {
        this.messages.push(messageResult);
        this.countretenu++;
      }
      if (messageResult.toId == 'appreciation') {
        this.messagesappreciation.push(messageResult);
        this.countretenu++;
      }


      for (let i = 0; i < this.messagesretenu.length; i++) {
        const indexretenu = this.messagesretenu[i];

        if (this.messagesretenu[i].toId == 'retenu') {

        } else if (this.messagesretenu[i].toId == 'exclus') { } else if (this.messagesretenu[i].toId == 'appreciation') { } else {
          this.countretenu--;
          this.count--;
          this.countapreciation--;

        }
      }

    }
  }

  initretenu() {
    this.countretenu = 0;
  }









}
