import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { CONFIG } from '../../../environments/environment';
import { GenericService } from '../../shared/services/GenericService';
import * as decode from 'jwt-decode';
import { User } from 'src/app/model/user';
import { Enseignant } from 'src/app/model/enseignant';
import {HttpClient, HttpHeaders} from '@angular/common/http';
@Injectable()
export class UtilisateursService {
    // We need Http to talk to a remote server.
    constructor(private _http: HttpClient) {

    }


    urls: any = {
        getByProfil: 'user/profil',
        changeStatutNotif: 'notification/statut',
        getUserNotification: 'user/notif/get'

    };




    // Get all salariers by etablissement
    url: string = CONFIG.URL + CONFIG.apiPath + 'user/getall';

    // Get all salariers by etablissement
    url100: string = CONFIG.URL + CONFIG.apiPath + 'user/prof';

    // Get all utlisiateur different de parain et d'enseignant

    url25: string = CONFIG.URL + CONFIG.apiPath + 'user/enseignants';

    // Add  user
    url2: string = CONFIG.URL + CONFIG.apiPath + 'user/add';


    // block  user
    url3: string = CONFIG.URL + CONFIG.apiPath + 'user/disable';

    // delete  user
    url5: string = CONFIG.URL + CONFIG.apiPath + 'user/remove';


    getByProfil(profil: string): Observable<any> {
        const decoded = decode(localStorage.getItem('token'));
        const username = decoded.sub;
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        const url = CONFIG.URL + CONFIG.apiPath + 'user/profil' + '?profil=' + profil;
        return this._http.get(url, { headers: headers });

    }

    getAllUtilisateurs(): Observable<any[]> {

        const decoded = decode(localStorage.getItem('token'));
        const username = decoded.sub;
        const url = CONFIG.URL + CONFIG.apiPath + 'user/getallbyetablissement' + '?username=' + username;
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
      return this._http.get<any>(url, { headers: headers });
    }

    getAllProf(): Observable<any[]> {

        const decoded = decode(localStorage.getItem('token'));
        const username = decoded.sub;
        const url = CONFIG.URL + CONFIG.apiPath + 'user/prof' + '?username=' + username;
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this._http.get<any>(url, { headers: headers });
    }

    getAllUtilisateur(): Observable<any[]> {

        const decoded = decode(localStorage.getItem('token'));
        const username = decoded.sub;
        const url = CONFIG.URL + CONFIG.apiPath + 'enseignants/getEnseignantNotlike' + '?username=' + username;
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
      return this._http.get<any>(url, { headers: headers });
    }

    addUser(username: string, password: string, nom: string, prenom: string, email: string,
        telephone: string, profil: string): Observable<any[]> {
        const decoded = decode(localStorage.getItem('token'));
        const usernames = decoded.sub;
        const url = CONFIG.URL + CONFIG.apiPath + 'user/add' + '?username=' + usernames;
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this._http.post<any>(url, ({
            username: email, password: password, nom: nom,
            prenom: prenom, email: email, telephone: telephone, profil: profil
        } || null)
            , { headers: headers })     ;
    }

    blockUser(username: string, disable: boolean): Observable<any[]> {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this._http.post<any>(this.url3, ({ username: username, disable: disable }), { headers: headers });
    }


    // update  user

    editUser(u: Enseignant): Observable<any[]> {
        const decoded = decode(localStorage.getItem('token'));
        const username = decoded.sub;
        const url = CONFIG.URL + CONFIG.apiPath + 'user/update';
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this._http.put<any>(url, u, { headers: headers });
    }

    deleteUser(username: string[]): Observable<any[]> {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this._http.post<any>(this.url5, username, { headers: headers });
    }
    deletUser(username: string[]): Observable<any[]> {

      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this._http.post<any>(this.url5, username, { headers: headers })
            ;
    }

    // archiver user
    archiverUser(email: any): any {
        const decoded = decode(localStorage.getItem('token'));
        const username = decoded.sub;
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        const url = CONFIG.URL + CONFIG.apiPath + 'user/archiver' + '?username=' + username;
        return this._http.post<any>(url, email, { headers: headers });

    }
    archiveUser(id: any): any {
        const decoded = decode(localStorage.getItem('token'));
        const username = decoded.sub;
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        const url = CONFIG.URL + CONFIG.apiPath + 'user/archivers' + '?username=' + username;

        return this._http.post<any>(url, id, { headers: headers });

    }


    countenseignant(): Observable<any[]> {
        const decoded = decode(localStorage.getItem('token'));
        const username = decoded.sub;
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        const url = CONFIG.URL + CONFIG.apiPath + 'user/countenseignant' + '?username=' + username;
        return this._http.get<any>(url, { headers: headers });
    }

    countcadre(): Observable<any[]> {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        const decoded = decode(localStorage.getItem('token'));
        const username = decoded.sub;
        const url = CONFIG.URL + CONFIG.apiPath + 'user/countcadreAdmin' + '?username=' + username;
        return this._http.get<any>(url, { headers: headers });
    }

    counteleve(): Observable<any[]> {
      const decoded = decode(localStorage.getItem("token"));
      const username = decoded.sub;
      const url =
        CONFIG.URL +
        CONFIG.apiPath +
        "user/countEleve" +
        "?username=" +
        username;
      const token = localStorage.getItem("token");
      const headers = new HttpHeaders().set("Authorization", "Bearer " + token);
      return this._http.get<any[]>(url, { headers: headers });
    }

    updatePassword(password:string, newPassword:string): Observable<any>{
      const name=localStorage.getItem('userName');
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
      return this._http.put(
        CONFIG.URL + CONFIG.apiPath +'user/changepassword'+'?name='+ name +'&newPassword='+ newPassword +'&password='+password,{ headers: headers }
      );
    }
}
