import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RessourcesElevesRoutingModule } from './ressources-eleves-routing.module';
import { RessourcesElevesComponent } from './ressources-eleves.component';
import { RessourcesDetailComponent } from './ressources-detail/ressources-detail.component';
import { FormsModule } from '@angular/forms';
import { SharedPipesModule } from 'src/app/shared/pipes/shared-pipes.module';
@NgModule({
  declarations: [RessourcesElevesComponent, RessourcesDetailComponent],
  imports: [
    CommonModule,
    RessourcesElevesRoutingModule,
    FormsModule,
    SharedPipesModule

  ]
})
export class RessourcesElevesModule { }
