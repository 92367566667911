import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { LoginService } from "./login/login/login.service";

import * as decode from "jwt-decode";
import { User } from "./model/user";

declare var $: any;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers: [LoginService]
})
export class AppComponent {
  public username;
  user: User = new User();
  public profil: any;
  u: User = new User();
  public admin = "Admin";
  public enseignant = "Enseignant";
  public parrain = "Parrain";
  expdate;
  dateNow;
  constructor(public router: Router, private loginService: LoginService) { }

  ngOnInit() {
    if((localStorage.getItem("token"))){
      var decoded = decode(localStorage.getItem("token"));
      this.expdate=decoded.exp;
      if(this.expdate){
        this.dateNow = Date.now();
        this.dateNow=Math.round(this.dateNow/1000)

        if((this.dateNow>this.expdate)){
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          this.router.navigate(["/login"]);
        }

      }}
      else{
        // this.router.navigate(["/login"]);
    }


    if (localStorage.getItem("token") != null) {
      return true;
    } else {
      return true;
    }
  }

  getUserbyusername() {
    if (localStorage.getItem("token") != null) {
      var decoded = decode(localStorage.getItem("token"));
      this.username = decoded.sub;
      this.loginService
        .getUserbyusername(this.username)

        .subscribe(
          user => {
            this.user = user;
            this.profil = this.user.profil;

            if (this.profil == "Parrain") {

              this.router.navigate(["/espaceParent"]);
            }
            if (this.profil == "Enseignant") {

              this.router.navigate(["/mon-emplois"]);
            }
            else {
              this.router.navigate(["/gestion-utilisateurs"]);
            }

          },
          error => {
            console.log(error);
          }
        );
    }
    return false;
  }
}
