import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CONFIG } from '../../../environments/environment';
import { Penalites } from '../rapport-statistique/professeur/penalites';
import { Message } from '../gestion-boite-lettre/message';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';

@Injectable()
export class AddboiteLettreService {


    constructor(private _http: HttpClient, private http: HttpClient) {

    }

    url: string = CONFIG.URL + CONFIG.apiPath + 'emplois/allJours';
    urlsemaine: string = CONFIG.URL + CONFIG.apiPath + 'emplois/allsemaine';
    url1: string = CONFIG.URL + CONFIG.apiPath + 'emplois/allheures';
    url10: string = CONFIG.URL + CONFIG.apiPath + 'user/prof';
    getAllJours(): Observable<any[]> {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this._http.get<any>(this.url, { headers: headers });
    }
    getAllSemaine(): Observable<any[]> {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this._http.get<any>(this.urlsemaine, { headers: headers });
    }
    getAllHeures(): Observable<any[]> {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this._http.get<any>(this.url1, { headers: headers });
    }
    getAllProf(): Observable<any[]> {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this._http.get<any>(this.url10, { headers: headers });
    }


    addMsg(message: any, reciever: any, sujet: any, username: any,file,fileName): Observable<any> {
        const baseUrl = CONFIG.URL + CONFIG.apiPath + 'messages/send';
        const body = ({
            message: message, reciever: reciever, sujet: sujet,
            username: username
        });
      const token = localStorage.getItem('token');
    

    const uploadFileData = new FormData();
    if(file){
      uploadFileData.append('file', file, file.name);
    }
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.post<any>(baseUrl,  {headers: headers}).map((data: Message) => data)
            .catch(error => {
                return new ErrorObservable(error);
            });
    }



    addMsg2(message: any, reciever: any, sujet: any, username: any): Observable<Message> {
        const url1250 = CONFIG.URL + CONFIG.apiPath + 'messages/send';
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this._http.post<any>(url1250, ({
            message: message, reciever: reciever, sujet: sujet,
            username: username
        } || null)
            , { headers: headers });
            ;
    }


    annulerMsg(message: any, reciever: any, sujet: any, username: any): Observable<Message> {
        const url1250 = CONFIG.URL + CONFIG.apiPath + 'messages/annuler';
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);


        return this._http.post<any>(url1250, ({
            message: message, reciever: reciever, sujet: sujet,
            username: username
        } || null)
            , { headers: headers })
            ;
    }



    retenuByadmin(pendto: Penalites, select: any, select2: any, select3: any, select1: any): any {
        const url126 = CONFIG.URL + CONFIG.apiPath + 'penalites/retenubyadmin' + '?prof=' + select + '&jour=' + select2 + '&heure=' + select3 + '&nomsemaine=' + select1;
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);


        return this._http.post(url126, pendto, { headers: headers });
    }
    avertByadmin(pendto: Penalites, select: any, select2: any, select3: any, select1: any): any {
        const url127 = CONFIG.URL + CONFIG.apiPath + 'penalites/advertissementbyadmin' + '?prof=' + select + '&jour=' + select2 + '&heure=' + select3 + '&nomsemaine=' + select1;
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);


        return this._http.post(url127, pendto, { headers: headers });
    }


    getprog(select: any, select1: any, select2: any, select3: any): Observable<any[]> {
        const url123 = CONFIG.URL + CONFIG.apiPath + 'programmes/programme/enseignant/date1' + '?prof=' + select + '&jour=' + select2 + '&heure=' + select3 + '&nom=' + select1;
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);

        return this._http.get<any>(url123, { headers: headers });
    }
}
