import { Component, OnInit } from '@angular/core';
import { EmploisService } from 'src/app/layout/gestion-emplois/emploisservice';
import { EspaceParrainService } from '../../service/espace-parrain.service';
import { Router, RouterLinkActive, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Observable, of } from 'rxjs';
import { EleveEmploi } from '../../service/eleve-emploi';
import { Location } from '@angular/common';
import { DatePipe } from "@angular/common";
import {HttpHeaders} from '@angular/common/http';


@Component({
  selector: 'app-emploi-temps',
  templateUrl: './emploi-temps.component.html',
  providers: [DatePipe],
  styleUrls: ['./emploi-temps.component.scss']
})
export class EmploiTempsComponent implements OnInit {

  displayedColumns: string[] = ['heureJour', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
  //heures: string[] = ['one', 'two', 'three', 'four', 'five'];



  jour: any;
  heure: any;
  lundi = 'Lundi';
  mardi = 'Mardi';
  mercredi = 'Mercredi';
  jeudi = 'Jeudi';
  vendredi = 'Vendredi';
  samedi = 'Samedi';
  heures: any[];
  jours = [
    { id: 1, jour: 'Lundi' },
    { id: 2, jour: 'Mardi' },
    { id: 3, jour: 'Mercredi' },
    { id: 4, jour: 'Jeudi' },
    { id: 5, jour: 'Vendredi' },
    { id: 6, jour: 'Samedi' }
  ];

  id: string;
  mySubscription: any;
  programme: EleveEmploi[];
  pg_test: EleveEmploi;
  classElev: any;
  semaines: any;
  semaActu: any;
  programmeCL: any;


  constructor(private emploisservice: EspaceParrainService, private datePipe: DatePipe,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private location: Location) {
  }

  ngOnInit() {
    this.router.navigated = false;
    this.getheures();
    this.id = this.activateRoute.snapshot.paramMap.get('id');
    this.classElev = this.activateRoute.snapshot.paramMap.get('c');
    //this.getEmploiEleve();
    this.getSemaine();



  }

  getheures() {

    this.emploisservice.getAllHeures()
      .subscribe((heuress) => {
        heuress.sort(function (a, b) {
          return a.id - b.id;
        });
        this.heures = heuress;
      }, error => {
      });
  }

  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

  getEmploiEleve() {
    console.log('numero: ' + this.id);
    this.emploisservice.getEmploiEleve(this.id)
      .subscribe(pg => this.programme = pg);
  }

  getSemaine() {
    this.emploisservice.getAllSemaine()
      .subscribe((semaines) => {
        this.semaines = semaines;
        console.log(semaines);
        this.getSemaineActuelle(0, semaines);
      }, error => {
        console.log(error);
      });
  }


  getSemaineActuelle(ED, semAC) {
    let date = new Date();
    let dateCheckS = this.datePipe.transform(date, "yyyy-MM-dd");
    let parts = [];
    parts = dateCheckS.split('-');
    let dateCheckED = new Date();
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    var dateCheck = new Date(parts[0], parts[1] - 1, parts[2]);
    dateCheckED.setDate(dateCheck.getDate() + ED);
    for (var i = 0; i < semAC.length; i++) {
      let dateFrom = new Date(semAC[i].datedebut);
      let dateTo = new Date(semAC[i].datefin);
      if ((dateCheckED.getTime() <= dateTo.getTime() && dateCheckED.getTime() >= dateFrom.getTime())) {
        this.semaActu = semAC[i];
      }
    }
    this.getEmploiEleveByclass(this.classElev);
  }

  getEmploiEleveByclass(classElev) {
    this.emploisservice.getEmploiCLasseSemaine(classElev, this.semaActu)
      .subscribe((pgc) => {
        this.programme = pgc;
        /*console.log('___________programmeCL classe______');
         console.log(this.programmeCL);*/
      }, error => {
        console.log(error);
      });
  }




  getPg(h: any, j: any) {
    if (this.programme != undefined) {
      let s = this.programme.find(pg => pg.jour.id === (j.id as number));
      this.pg_test = this.programme.find(pg => pg.jour.id === j.id && pg.heure.id === h.id);
      if (this.pg_test != undefined)
        if (this.pg_test.prof != undefined)
          return this.pg_test;


    }
  }

  clickGoBack() {
    this.location.back();
  }

}
