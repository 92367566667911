import { Component, OnInit } from '@angular/core';
import * as decode from 'jwt-decode';
import { User } from 'src/app/model/user';
import { LoginService } from '../../login/login/login.service';
import swal from 'sweetalert2';
import { DomSanitizer } from '@angular/platform-browser';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-edit-profil',
  templateUrl: './edit-profil.component.html',
  styleUrls: ['./edit-profil.component.scss']
})
export class EditProfilComponent implements OnInit {
  user: User = new User();
  u: User = new User();
  public username;
  public profil: any;
  thumbnail: any;

  constructor(private loginService: LoginService, private sanitizer: DomSanitizer, private router:Router) { }

  ngOnInit() {
    this.getUserbyusername();
    this.loginService.getAllimage()
      .subscribe((imagetable) => {
        let retrievedImage = 'data:image/jpeg;base64,' + imagetable;
        this.thumbnail = this.sanitizer.bypassSecurityTrustUrl(retrievedImage)
      }, error => {
        console.log(error);
      });
  }
  getUserbyusername() {
    var decoded = decode(localStorage.getItem('token'));
    this.username = decoded.sub;
    this.loginService.getUserbyusername(this.username)

      .subscribe((user) => {
        this.user = user;
        this.profil = this.user.profil;
      }, error => {
        console.log(error);
      });
  }



  makePass2() {
    //this.t = Math.random().toString(36).slice(-8) + Math.random().toString(36).slice(2);
    var possible = "012#%&+@3456789#%&+@ABCDEFGH#%&+@IJKLMNOPQRST#%&+@0123456#%&+@789UVWXYZabcdefghijklmnopqrs#%&+@tuvwxyz012#%&+@3456789";
    let t = "";
    for (var i = 0; i < 11; i++) {
      t += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    this.user.password = t;
  }

  editprof(user, email, phone_number, username, password) {
    console.log(user);
    this.loginService.editUser(user)
      .subscribe((res) => {
        swal({
          position: 'center',
          title: 'Modifcation effectuée avec succès',
          // showConfirmButton: false,
          confirmButtonText: 'OK!',
          timer: 1500
        })
      }),
      error => {
        alert(error);
      };
  }
retour(){
  this.router.navigate(['/DashboardDirecteur']);
}

}
