import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { EleveEmploi } from './../../espace-parent/service/eleve-emploi';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { CONFIG } from '../../../environments/environment';
import * as decode from 'jwt-decode';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/xml',
    'Authorization': 'jwt-token'
  })
};
@Injectable({
  providedIn: 'root'
})
export class SaisieDesNotesService {
  constructor(private http: HttpClient, private _http: HttpClient) { }
  getClasseProf(username: any): Observable<any[]> {
    const url = CONFIG.URL + CONFIG.apiPath;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get<any[]>(url + `emplois/getAllClasseByProf?username=${username}`, {headers: headers});
  }

  getListEleve(classe: any): Observable<EleveEmploi[]> {
    const url = CONFIG.URL + CONFIG.apiPath;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get<EleveEmploi[]>(url + `eleve/allbyclass/?nomclasse=${classe}`, {headers: headers});
  }


save(note: any){
  const decoded = decode(localStorage.getItem('token'));
    const usernames = decoded.sub;
    const url = CONFIG.URL + CONFIG.apiPath + 'notes/save';
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this._http.post<any>(url, note, { headers: headers });

}

addMoyenneAnnuelle(classeShosed: string, eleve: string): Observable<any[]>{
  const decoded = decode(localStorage.getItem('token'));
  console.log("yowzaa decode", decoded);
  const usernames = decoded.sub;
  const body =({
    classeShosed: classeShosed, eleve: eleve
   
  })                                 
  const url = CONFIG.URL+CONFIG.apiPath+ 'moyenneAnnuelle/calculeMoyenneAnnuelle?classe='+ classeShosed +'&eleve='+ eleve;
  const token = localStorage.getItem('token');
  const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token );
  return this._http.post<any>(url,body, {headers: headers});

}
AddMoyTrimestres(classeShosed: string, eleve: string,  nomTrimestre: string): Observable<any[]>{
  const decoded = decode(localStorage.getItem('token'));
  console.log("yowzaa decode", decoded);
  const usernames = decoded.sub;
  const body =({
    classeShosed: classeShosed, eleve: eleve, nomTrimestre: nomTrimestre
   
  })                                 
  const url = CONFIG.URL+CONFIG.apiPath+ 'moyenneTrimestre/CalculeMoyTrimestre?classe='+ classeShosed +'&eleve='+ eleve+'&trimestre=' + nomTrimestre;
  const token = localStorage.getItem('token');
  const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token );
  return this._http.post<any>(url,body, {headers: headers});

}
AddMoyMatiereTrimestres(classeShosed: string, eleve: string, matiere: string, nomTrimestre: string): Observable<any[]>{
  const decoded = decode(localStorage.getItem('token'));
  console.log("yowzaa decode", decoded);
  const usernames = decoded.sub;
  const body =({
    classeShosed: classeShosed, eleve: eleve,
    matiere: matiere, nomTrimestre: nomTrimestre
  })                                 
  const url = CONFIG.URL+CONFIG.apiPath+ 'moyenneMatiereTrimestre/CalculeMoyMatiereTrimestre?classe='+ classeShosed +'&eleve='+ eleve+ '&matiere=' + matiere+'&trimestre=' + nomTrimestre;
  const token = localStorage.getItem('token');
  const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token );
  return this._http.post<any>(url,body, {headers: headers});

}

AddMoyMat(classeShosed: string, eleve: string,sendedEpeuve: string, matiere: string,nomTrimestre: string ): Observable<any[]>{
  const decoded = decode(localStorage.getItem('token'));
  console.log("yowzaa decode", decoded);
  const usernames = decoded.sub;
  const body = ({
    classeShosed: classeShosed, eleve: eleve, sendedEpeuve: sendedEpeuve,
    matiere: matiere, nomTrimestre: nomTrimestre
});
  const url = CONFIG.URL + CONFIG.apiPath+ 'moyenneMatiere/ajoutMoyMat?classe=' + classeShosed +'&eleve='+ eleve+ '&epreuve=' + sendedEpeuve+  '&matiere=' + matiere+'&trimestre=' + nomTrimestre ;
  const token = localStorage.getItem('token');
  const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token );
    return this._http.post<any>(url,body , {headers: headers});
 

}

  addNoteEleve(formNote, classeShosed: string, eleve: string, nomTrimestre: string, sendedEpeuve: string, username: string , valeur: any): Observable<any[]> {
    const decoded = decode(localStorage.getItem('token'));
    const usernames = decoded.sub;
    const url = CONFIG.URL + CONFIG.apiPath + 'notes/ajoutNote?classe=' + classeShosed + '&epreuve=' + sendedEpeuve+'&eleve='+ eleve+ '&trimestre=' + nomTrimestre + '&username=' + username + '&valeur=' + valeur ;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this._http.post<any>(url, formNote
      , { headers: headers });
  }

  getUserbyusername(username: string): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    const url = CONFIG.URL + CONFIG.apiPath;
    return this.http.get<any>(url + `user/getbyusername?username=${username}`, {headers: headers});
  }



}
