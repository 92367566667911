import { Component, OnInit } from '@angular/core';
import { EspaceParrainService } from '../../service/espace-parrain.service';
import { ActivatedRoute } from '@angular/router';
import { EleveAbsence } from '../../service/eleve-absence';
import { Location } from '@angular/common';
import { MatTableDataSource } from '@angular/material';
import { routerTransition } from 'src/app/router.animations';

@Component({
  selector: 'app-list-absence',
  templateUrl: './list-absence.component.html',
  styleUrls: ['./list-absence.component.scss'],
  animations: [routerTransition()],

})
export class ListAbsenceComponent implements OnInit {

  public displayedColumns: string[] = ['dateAbsence', 'matiere', 'nomClasse'];
  public dataSource = new MatTableDataSource<EleveAbsence>();

  public id: string;
  elevName: any;
  public listAbsence: EleveAbsence[];

  constructor(private espaceParrainService: EspaceParrainService,
    private activateRoute: ActivatedRoute,
    private location: Location) { }


  ngOnInit() {
    this.id = this.activateRoute.snapshot.paramMap.get('id');
    this.getListAbsenceEleveByInsc();
  }

  getListAbsenceEleveByInsc() {


    this.espaceParrainService.getListAbsenceEleveByNuminsc(this.id)
      .subscribe(res => {
        if (res.length == 0) {
          this.listAbsence = [{ prenomEleve: '', date_Absence: '', matiere: '', nomClasse: '', type: '', remarque: '' }];
        } else {

          let newTab2 = [];
          res.forEach(function (ithem) {
            newTab2.push({ dateAbsence:ithem.date_Absence, heurAbs: ithem.date_Absence, matiere: ithem.matiere, classe: ithem.nomClasse })
          });
          this.elevName = res[0].prenomEleve;
          this.listAbsence = newTab2.slice().sort((a, b) => Date.parse(b.dateAbsence) - Date.parse(a.dateAbsence));
        }


        console.log(res);
        console.log(this.listAbsence);
        this.dataSource.data = this.listAbsence;
      }
      );
  }

  clickGoBack() {
    this.location.back();
  }

}
