// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import * as https from 'https';

export const CONFIG = {
  production: true,

// URL:"http://localhost:8090/api/",
// URL: "http://185.250.36.156:8080/smartschool-0.0.1-SNAPSHOT/",
 // URL: "https://back.dsms.dpc.com.tn/dsms-0.0.1-SNAPSHOT/",
  URL: "https://dsms.back.dpc.com.tn/api/",


firebaseConfig : {
  apiKey: "AIzaSyAy_eLnm4lrtIY-iinKgAuME3_lJox-i1A",
  authDomain: "smartschool-a4098.firebaseapp.com",
  databaseURL: "https://smartschool-a4098-default-rtdb.firebaseio.com",
  projectId: "smartschool-a4098",
  storageBucket: "smartschool-a4098.appspot.com",
  messagingSenderId: "983023844140",
  appId: "1:983023844140:web:1b67f8237718916b8d0a89",
  measurementId: "G-FQFJZ9SLSG"
},

/*
firebaseConfig : {
  apiKey: "AIzaSyANiB0upEQBsKqZYUltElMWEoMpv3eQjbY",
  authDomain: "dsms-e1a11.firebaseapp.com",
  projectId: "dsms-e1a11",
  storageBucket: "dsms-e1a11.appspot.com",
  messagingSenderId: "378635055595",
  appId: "1:378635055595:web:66c0e00f086e0b996ba9ae",
  measurementId: "G-WVEKWFFNR1"
},
*/

 /* firebaseConfig : {
  apiKey: "AIzaSyByM-s_kzeK9s7uqBmVeZ5aKLBdx8uFCB4",
  authDomain: "dsmsoriginal.firebaseapp.com",
  databaseURL: "https://dsmsoriginal-default-rtdb.firebaseio.com",
  projectId: "dsmsoriginal",
  storageBucket: "dsmsoriginal.appspot.com",
  messagingSenderId: "1047080943158",
  appId: "1:1047080943158:web:240b5955aa91e0a1182297",
  measurementId: "G-055BVPZLD0"
},
 */


 // URLsocket: "http://207.180.211.158:8888/",
 // URLsocketnote: "http://207.180.211.158:8889/",
  apiPath: "api/",
  entete: `
      <img src="http://51.254.129.164/assets/images/apen.PNG" width="50" alt="Apen">
  `,
  googleMapsAPI: "AIzaSyAHvIyK6M1SW6R1rYkXnTWNiCbbqE9gBVI"
};
//console.log(URL);

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
