import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-espace-eleve',
  templateUrl: './espace-eleve.component.html',
  styleUrls: ['./espace-eleve.component.scss']
})
export class EspaceEleveComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  thumbnailTest = true;

  onLoggedout() {
    localStorage.removeItem('token');
  }

}
