import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settingnavbar',
  templateUrl: './settingnavbar.component.html',
  styleUrls: ['./settingnavbar.component.scss']
})
export class SettingnavbarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
