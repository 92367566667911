import { Etablissement } from "./Etablissement";

export class User {


    public id: number;

public sexe :string ;
    public username: string;
    public anciennete: any ; 
    public nombreHeureEffective:number ;
    public nombreHeureSupplimentaire: number ;
    public nombreHeureSoutien: number ;
    public nbrheures:number ;
    public password: string;
    public newPassword: string;
    public profil: string;
    public nom: string;
    public prenom: string;
    public tel: number;
    public email: string;
    public langue: string;
    public etablissement : Etablissement
    public couverture_sociale:boolean;
    public risque_personnels : boolean;
    //public photo: string,
    public enabled: boolean = true;constructor() {
}

}




