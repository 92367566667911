import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CONFIG } from 'src/environments/environment';
import { EleveParrain } from '../service/eleve-parrain';

@Injectable({
  providedIn: 'root'
})
export class RessourcesElevesService {

  constructor(private http: HttpClient) {

  }

  url: string = CONFIG.URL + CONFIG.apiPath;


  getElevesByParrain(username: any): Observable<EleveParrain[]> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get<EleveParrain[]>(this.url + 'eleve/allbyParrain?username=' + username, { headers: headers });
  }

  getAllDocsByNumInscri(numInscrit: any): Observable<Document[]> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get<Document[]>(this.url + 'document/getDocsByNumInscri?numInscri=' + numInscrit, { headers: headers })

  }
  downloadRessource(filename: string):Observable<any>{
    const token = localStorage.getItem("token");
    const headers = new HttpHeaders().set("Authorization", "Bearer " + token);
    const url = CONFIG.URL + CONFIG.apiPath+`document/getFileByName/?filename=${filename}`;
    return this.http.get<any>(url, {headers: headers})
  }

}
