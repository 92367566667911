import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { routerTransition } from 'src/app/router.animations';
import { EspaceParrainService } from '../../service/espace-parrain.service';

@Component({
  selector: 'app-list-appreci',
  templateUrl: './list-appreci.component.html',
  styleUrls: ['./list-appreci.component.scss'],
  animations: [routerTransition()],

})
export class ListAppreciComponent implements OnInit {

  public displayedColumns: string[] = ['date_appreciation', 'description', 'matiere', 'enseignant'];
  public dataSource = new MatTableDataSource<EleveApres>();
  id: any;
  listApres: any;

  constructor(private activateRoute: ActivatedRoute, private espaceParrainService: EspaceParrainService,) { }

  ngOnInit() {
    this.id = this.activateRoute.snapshot.paramMap.get('id');
    this.getListAppreEleveByInsc();
  }

  getListAppreEleveByInsc() {
    this.espaceParrainService.getListAppreEleveByInsc(this.id)
      .subscribe(res => {
        if (res.length == 0) {
          this.listApres = [{ motif: '', dateAppres: '', matiere: '', ens: '' }];
        } else {
          this.listApres = res;
          let newTab2 = [];
          this.listApres.forEach(function (ithem) {
            console.log(ithem.dateAppres);
            newTab2.push({ motif: ithem[1], dateAppres: new Date(ithem[0]), matiere: ithem[2], ens: ithem[4] + ' ' + ithem[3] })
          });

          // this.listPenalite = newTab2;

          this.listApres = newTab2.slice().sort((a, b) => Date.parse(b.dateAppres) - Date.parse(a.dateAppres));

        }

        console.log(res);
        console.log(this.listApres);
        this.dataSource.data = this.listApres;
      }
      );
  }

}


export class EleveApres {

  public date_appreciation: string;
  public description: string;


  public constructor() {

  }
}

