import { Component, OnInit } from '@angular/core';
import { GestionCantinesService } from '../../layout/gestion-cantines/gestion-cantines.service';
import {HttpHeaders} from '@angular/common/http';
import { LoginService } from 'src/app/login/login/login.service';
import { Router } from '@angular/router';
import * as decode from 'jwt-decode';
@Component({
  selector: 'app-cantine-eleve',
  templateUrl: './cantine-eleve.component.html',
  styleUrls: ['./cantine-eleve.component.scss']
})
export class CantineEleveComponent implements OnInit {
  cantineJour: any;
  loading :boolean =false ;
  user:any;
  username:any;
  constructor(private cantinesService: GestionCantinesService,
    private loginService: LoginService,
    public router: Router) { }

  ngOnInit() {
    this.getAllCantineJour();
    this.getbyusername();
  }

 getbyusername(){
  this.loading=true ;
  var decoded = decode(localStorage.getItem("token"));
  this.username = decoded.sub;
  this.loginService.getUserbyusername(this.username).subscribe((res=>
    {
      this.user = res;
                if (this.user.profil == "Parrain") {
                  this.router.navigate(["/espaceParent"]);
                } else if (this.user.profil == "Enseignant") {
                  this.router.navigate(["./mon-emplois"]);
                }else if (this.user.profil == "Directeur") {
                    this.router.navigate(["DashboardDirecteur"]);
                  }
    }))
    this.loading=false;
}
  getAllCantineJour() {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    this.cantinesService.getAllCantineJour().subscribe((data) => {
      this.cantineJour = data;


    }, error => {
    });
  }

}
