import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { LoginService } from '../../login/login/login.service';
import * as decode from 'jwt-decode';
import { User } from '../../model/user';
import { DomSanitizer } from '@angular/platform-browser';
import * as $ from 'jquery';
import { ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-asidenav',
  templateUrl: './asidenav.component.html',
  styleUrls: ['./asidenav.component.scss'],
  providers: [LoginService]
})
export class AsidenavComponent implements OnInit {
  public username;
  user: User = new User();
  public profil: any;
  u: User = new User();
  public admin = "Admin";
  public enseignant = "Enseignant";
  thumbnail: any;

  //@ViewChild('#Surveillant') private draggableElement: ElementRef;
  // @ViewChild('Surveillant') htmlData: ElementRef;

  constructor(private loginService: LoginService, private sanitizer: DomSanitizer, private cdr: ChangeDetectorRef) {

  }

  ngOnInit() {
    /*
    let del = document.getElementById("Surveillant");
    console.log('eeeeeeeeeeeeeeeeee');
    console.log(del);*/

    /*
      this.loginService.getAllimage()
        .subscribe((imagetable) => {
          let retrievedImage = 'data:image/jpeg;base64,' + imagetable;
          this.thumbnail = this.sanitizer.bypassSecurityTrustUrl(retrievedImage)
        }, error => {
          console.log(error);
        });
        */
    this.cdr.detectChanges();
    this.getUserbyusername();

    /*jQuery(document).ready(() => {
      const trees: any = jQuery('[data-widget="tree"]');
      trees.tree();
    });*/

    $(document).ready(() => {
      const metisMenu: any = jQuery('.vertical-nav-menu');
      metisMenu.metisMenu();

      //$(".vertical-nav-menu").metisMenu();
    });

  }


  getUserbyusername() {


    var decoded = decode(localStorage.getItem('token'));
    this.username = decoded.sub;
    this.loginService.getUserbyusername(this.username)

      .subscribe((user) => {
        this.user = user;
        this.profil = this.user.profil;
        /*console.log('_________profil______________');
        console.log(this.profil);*/
      }, error => {
        console.log(error);
      });
  }
}
