import { Component, OnInit, ViewChild } from '@angular/core';
import { routerTransition } from '../../router.animations';
import { RolesService } from "./gestion-roles.service";
import { Subject } from "rxjs/Subject";
import { debounceTime } from 'rxjs/operator/debounceTime';
import swal from 'sweetalert2';

import * as decode from 'jwt-decode';
import { SelectionComponent } from '../../shared/generic-components/selection.component';
import { User } from '../../model/user';
import { UtilisateursService } from '../gestion-utilisateurs/gestion-utilisateurs.service';
import { AccessUrl } from './role';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/login/login/login.service';
@Component({
    selector: 'app-gestion-roles',
    templateUrl: './gestion-roles.component.html',
    styleUrls: ['./gestion-roles.component.scss'],
    animations: [routerTransition()],
    providers: [UtilisateursService],
    entryComponents: []
})
export class GestionRolesComponent extends SelectionComponent implements OnInit {
    access: Access = new Access;
    public username;
    user: User = new User();
    currentPage: number = 1;
    roles: Role[] = [];
    newRole: Role = new Role();
    adding: boolean;
    editing: number;
    val:Role=new Role();
    addingUsers: boolean;
    loading :boolean =false ;
    private _success = new Subject<string>();
    private _error = new Subject<string>();

    staticAlertClosed = false;
    successMessage: string;
    errorMessage: string;

    objectName = 'role'; // used for messages
    messages: any = {
        delete: 'Voulez vous vraiment supprimer les ' + this.objectName + 's selectionnées ?',
    };


    constructor(private rolesService: RolesService, private loginService: LoginService,
        public router: Router) {
        super();

    }

    getRoles() {
        this.rolesService.getAllAuthorities()
            .subscribe((authorities) => {
                this.roles = authorities;
            }, error => {
                console.log(error);
            });
    }

    ngOnInit() {
        setTimeout(() => this.staticAlertClosed = true, 2000);
        this._success.subscribe((message) => this.successMessage = message);
        debounceTime.call(this._success, 2000).subscribe(() => this.successMessage = null);

        setTimeout(() => this.staticAlertClosed = true, 2000);
        this._error.subscribe((message) => this.errorMessage = message);
        debounceTime.call(this._error, 2000).subscribe(() => this.errorMessage = null);
        this.getRoles();
        if (localStorage.getItem('token') != null) {
            var decoded = decode(localStorage.getItem('token'));
            this.username = decoded.sub;
        }


        this.accessdenied();
        this.getbyusername();
    }

   getbyusername(){
    this.loading=true ;
    var decoded = decode(localStorage.getItem("token"));
    this.username = decoded.sub;
    this.loginService.getUserbyusername(this.username).subscribe((res=>
      {
        this.user = res;
                  if (this.user.profil == "Parrain") {
                    this.router.navigate(["/espaceParent"]);
                  } else if (this.user.profil == "Enseignant") {
                    this.router.navigate(["./mon-emplois"]);
                  }else if (this.user.profil == "Eleve") {
                    this.router.navigate(["./espaceEleve"]);
                  }
      }))
      this.loading=false;
  }

    chek(event, newRole) {
        if (event.keyCode == 13) {

        }
    }

    save(newRole) {
        let newName = newRole.description.split(' ').join('_');
        newRole.setName('ROLE_' + newName);
        this.rolesService.addAuthority(newRole)
            .subscribe((data) => {
                if (data) {
                    this.getRoles();
                    this._success.next(`Role enregistré avec succés`);
                }
                else {
                    this._error.next(`Role déja existant`);
                }
                this.newRole = new Role();
            });
        this.adding = false;
    }


    saveEditRole(GroupeToEdit) {
        let newName = GroupeToEdit.description.split(' ').join('_');
        GroupeToEdit.name = 'ROLE_' + newName;
        this.rolesService.editAuthority(GroupeToEdit)
            .subscribe((res) => {
                if (res['response']) {
                    this.editing = -1;
                    this.getRoles();
                }
                else {
                    this.getRoles();
                    this._error.next(`Role déja existant`);
                }
            });
    }
    deletRole(roles) {

        this.rolesService.deleteRole(this._selected).subscribe((res) => {

            if (res['response']) {
                this.getRoles();
            }
        })

    }


    deleteRole() {

        if (confirm(this.messages.delete)) {

            this.rolesService.deleteRole(this._selected).subscribe((res) => {

                if (res['response']) {
                    this.getRoles();
                }
            })
        }
    }
    deletRoles(user: AccessUrl) {
        swal({
            title: 'Êtes-vous sûr?',
            text: "Vous ne pouvez pas revenir en arrière!",
            showCancelButton: true,
            cancelButtonText: "Annuler",
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, supprimez-le(s)!'
        }).then((result) => {
            if (result.value) {
                swal(
                    'Supprimé!',
                    'Vos données ont été supprimées.',
                    'success'
                )

                this.rolesService.DeleteRoles(user.id)
                    .subscribe((res) => {

                        if (res['response']) {
                            this.getRoles();
                        }
                    })
            }


        })

    }

    getUserbyusername() {

        this.rolesService.getUserbyusername(this.username)
            .subscribe((user) => {
                this.user = user;
            }, error => {
                console.log(error);
            });
    }
getRoleDescription(a){
    console.log(a);
this.val=a
    return a;
}
    getAccess(profil: any) {
        this.rolesService.getAccess('Gestion des roles', this.user.profil)
            .subscribe((access) => {
                this.access = access;
            }, error => {
                console.log(error);
            });
    }
    accessdenied() {

        if (localStorage.getItem('token') != null) {
            var decoded = decode(localStorage.getItem('token'));

            this.username = decoded.sub;
            this.rolesService.getUserbyusername(this.username)
                .subscribe((user) => {
                    this.user = user;

                    this.getAccess(user.profil);


                }, error => {
                    console.log(error);
                });


        }
    }
}
export class Role {
    public id: number;
    public name: string;
    public description: string;

    public constructor() {
    }

    setName(name: string) {
        this.name = name;
    }

}
export class Access {
    public id: number;

    public addop: Boolean;
    public getbyidop: Boolean;
    public getallop: Boolean;
    public updateop: Boolean;

    public removeop: Boolean;
    public gestion: string;
    public constructor() {
    }



}
