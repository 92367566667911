import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { CONFIG } from '../../../environments/environment';
import { Emplois } from './mon-emplois.component';
import { Message } from 'src/app/model/messagesecoket';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as decode from 'jwt-decode';





@Injectable()
export class EspaceprofesseurService {
    constructor(private _http: HttpClient, private http: HttpClient) {

    }
    messages: Message[] = [];

    urlservice = CONFIG.URL + CONFIG.apiPath + 'socket/getnotifparent?numinscri=';

    url1: string = CONFIG.URL + CONFIG.apiPath + 'emplois/allheures';


    url6: string = CONFIG.URL + CONFIG.apiPath + 'emplois/getseance';


    url20: string;
    url10: string = CONFIG.URL + CONFIG.apiPath + 'absence/add';

    url29: string = CONFIG.URL + CONFIG.apiPath + 'absence/updateElevesPresence';
  
  username: string;

    getparent(numinscri: any): Observable<any[]> {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);

        return this._http.get<any>(this.urlservice + numinscri, { headers: headers });

    }

    getAllbyusername(username: string): Observable<any[]> {
        const urln = CONFIG.URL + CONFIG.apiPath + 'emplois/allbyprof' + '?username=' + username;
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this._http.get<any>(urln, { headers: headers });
    }
    getemploisbyprofandday(username: string, jour: string): Observable<any[]> {
        const myParams = new URLSearchParams();
        myParams.set('username', username);
        myParams.set('jour', jour);
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
      const urlns = CONFIG.URL + CONFIG.apiPath + 'emplois/allbyprofday' + '?username=' + username + '&jour=' + jour;
        return this._http.get<any>(urlns, {headers: headers});
    }
    getAllHeures(): Observable<any[]> {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this._http.get<any>(this.url1, { headers: headers });
    }
    findSeanceByProf(username: string, jour: string, heure: string): Observable<Emplois> {
        console.log('le username est : ' + username + '->le jour est :' + jour + '->lheure de debut est :' + heure);

        const urlns = CONFIG.URL + CONFIG.apiPath + 'emplois/getseance' + '?username=' + username + '&jour=' + jour + '&heure=' + heure;

      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this._http.get<Emplois>(urlns, { headers: headers });

    }
    addAbsence(id: any, num: any[]): Observable<any[]> {
        console.log(num);
        this.url20 = CONFIG.URL + CONFIG.apiPath + 'absence/add' + '?idSeance=' + id;
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);

        return this._http.post<any>(this.url20,
            num, { headers: headers }) ;
    }


    exclus(id: any, numInscription: any, remarque: any): Observable<any> {

        this.url20 = CONFIG.URL + CONFIG.apiPath + 'penalites/exclus' + '?id=' + id;

      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this._http.post(this.url20,({
            numInscription: numInscription, remarque: remarque
        } || null)
            , { headers: headers })
            ;

    }

    excluse(id: any, numInscription: any, remarque: any): Observable<any> {

        this.url20 = CONFIG.URL + CONFIG.apiPath + 'penalites/excluse' + '?id=' + id;

      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);

        return this._http.post(this.url20, ({
            numInscription: numInscription, remarque: remarque
        } || null)
            , { headers: headers })

            ;

    }

    avertissement(id: any, numInscription: any, remarque: any): Observable<any> {
        this.url20 = CONFIG.URL + CONFIG.apiPath + 'penalites/avertissement' + '?id=' + id;
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this._http.post(this.url20, ({
            numInscription: numInscription, remarque: remarque
        } || null)
            , { headers: headers })
            ;
    }


    appreciation(id: any, numInscription: any, description: any, date_appreciation: any): Observable<any> {

        this.url20 = CONFIG.URL + CONFIG.apiPath + 'appreciation/add' + '?idprogramme=' + id + '&numinscri=' + numInscription;
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this._http.post(this.url20, ({
            description: description, date_appreciation: date_appreciation
        } || null)
            , { headers: headers })
            ;
    }

    excluswithnotif(id: any, numInscription: any, remarque: any): Observable<any> {

        this.url20 = CONFIG.URL + CONFIG.apiPath + 'penalites/exclus' + '?id=' + id;

      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);

        return this._http.post(this.url20, ({
            numInscription: numInscription, remarque: remarque
        } || null)
            , { headers: headers })
            .map((response: Response) => {
                this.messages.push(response.json());
            }



            )
            ;

    }
    posts(data: Message) {
        const url = CONFIG.URL + 'api/socket';
        return this.http.post(url, data)
            ;
    }
    retenu(id: any, numInscription: any, remarque: any): Observable<any[]> {

        this.url20 = CONFIG.URL + CONFIG.apiPath + 'penalites/retenu' + '?id=' + id;
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);

        return this._http.post<any>(this.url20, ({
            numInscription: numInscription, remarque: remarque
        } || null)
            , { headers: headers })
            ;

    }

    updateElevesPresence(numInscriptions: string[]): Observable<any[]> {

      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this._http.post<any>(this.url29, numInscriptions, { headers: headers })
            ;
    }

    /*service  dashbord ensiegant */

    getAllAgenda(): Observable<any[]> {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        const urlns = CONFIG.URL + CONFIG.apiPath + 'agenda/getallAgendaNotExpired';
        return this._http.get<any>(urlns, { headers: headers });
    }

    getAlltravaux(): Observable<any[]> {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        const urlns = CONFIG.URL + CONFIG.apiPath + 'travaux/getall';
        return this._http.get<any>(urlns, { headers: headers });
    }


    getAllCantineJour(): Observable<any[]> {
        const url = CONFIG.URL + CONFIG.apiPath + 'cantines/getallparjouraujourdhui';
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this._http.get<any>(url, { headers: headers });
    }
    getAllTravailAFaire(): Observable<any[]> {
      const decoded = decode(localStorage.getItem('token'));
    this.username = decoded.sub;
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        const urlns = CONFIG.URL + CONFIG.apiPath + 'ulpoadTravail/getallTravailDemandeNotExpired' + '?username=' + this.username;
        return this._http.get<any>(urlns, { headers: headers });





    }
    getAllRendreparELEVE(id: any): Observable<any[]> {
    const decoded = decode(localStorage.getItem('token'));
  this.username = decoded.sub;
      const urlns = CONFIG.URL + CONFIG.apiPath + 'ulpoadTravail/getAllCRbyTD' + '?idTD=' + id;
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
      return this._http.get<any>(urlns, { headers: headers });





  }
  getAllCasier(): Observable<any[]> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
  const decoded = decode(localStorage.getItem('token'));
this.username = decoded.sub;
    return this.http.get<any[]>(CONFIG.URL + CONFIG.apiPath + `casierNum/getAllCasier?userConnect=${this.username}`, {headers: headers});
  }

}
