import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { CONFIG } from '../../../environments/environment';
import { Matiere } from './matiere';
import { Access } from '../gestion-roles/gestion-roles.component';
import { User } from '../gestion-utilisateurs/user';

import * as decode from 'jwt-decode';
import {HttpClient, HttpHeaders} from '@angular/common/http';
@Injectable()
export class MatiereService {

    constructor(private _http: HttpClient) {

    }
    url5: string = CONFIG.URL + CONFIG.apiPath + 'matiere/remove';
    url: string = CONFIG.URL + CONFIG.apiPath + 'matiere/all';
    url4: string = CONFIG.URL + CONFIG.apiPath + 'matiere/update';



    addMatiere(matiere, trimestre): Observable<any[]> {
      const decoded = decode(localStorage.getItem('token'));
      const username = decoded.sub;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
      const url = CONFIG.URL + CONFIG.apiPath + 'matiere/addMat' + '?trimestre=' + trimestre;
      return this._http.post<any>(url,matiere , { headers: headers });
  }


    getAllmatiere(): Observable<any> {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        const url6123 = CONFIG.URL + CONFIG.apiPath + 'matiere/all';
        let a: any;
        return a = this._http.get(url6123, { headers: headers });
      }

    getAllMatieres(): Observable<any[]> {
        const decoded = decode(localStorage.getItem('token'));
        const username = decoded.sub;

        const url = CONFIG.URL + CONFIG.apiPath + 'matiere/all';
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this._http.get<any>(url, { headers: headers });
    }
   
    editMatiere(matiere: Matiere): Observable<any[]> {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this._http.post<any>(CONFIG.URL + CONFIG.apiPath + 'matiere/update/'+matiere.idmatiere, (matiere || null), { headers: headers });
    }
    editMatiere1(nomMatiere: any): Observable<any[]> {
        const decoded = decode(localStorage.getItem('token'));
        const username = decoded.sub;
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        const url = CONFIG.URL + CONFIG.apiPath + 'matiere/update' + '?username=' + username;
        return this._http.post<any>(this.url, nomMatiere, { headers: headers })
            ;
    }

    deleteMatiere(nomMatiere: any): any {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this._http.post(this.url5, nomMatiere, { headers: headers })
            ;
    }
    archiver(idmatiere: any): any {
        const decoded = decode(localStorage.getItem('token'));
        const username = decoded.sub;
        console.log(username);
        console.log(idmatiere);

        const url6 = CONFIG.URL + CONFIG.apiPath + 'matiere/archiver' + '?username=' + username;
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this._http.post(url6, idmatiere, { headers: headers })
            ;

    }
    getUserbyusername(username: string): Observable<User> {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        const url6 = CONFIG.URL + CONFIG.apiPath + 'user/getbyusername' + '?username=' + username;
        console.log(url6);
        let a: any;
        console.log(this._http.get(url6, { headers: headers }));
        return a = this._http.get<User>(url6, { headers: headers });


    }
    getAccess(gestion: string, role: string): Observable<Access> {

      
      const url1 = CONFIG.URL + 'api/role/getaccess' + '?gestion=' + gestion + '&role=' + role;
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
      return this._http.get<Access>(url1, { headers: headers });
    }
}
