import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { CONFIG } from '../../../environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GestionCantinesService {

  constructor(private _http: HttpClient) { }



  getAllCantineJour(): Observable<any[]> {
    const url = CONFIG.URL + CONFIG.apiPath + 'cantines/getallparjouraujourdhui';
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this._http.get<any>(url, { headers: headers });
  }

  getAllCantine(): Observable<any[]> {
    const url = CONFIG.URL + CONFIG.apiPath + 'cantines/getall';
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this._http.get<any>(url, { headers: headers });
  }

  getAllMenu(): Observable<any[]> {
    const url = CONFIG.URL + CONFIG.apiPath + 'menu/getAll';
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this._http.get<any>(url, { headers: headers });
  }

  getCantineById(id:any): Observable<any[]> {
    const url = CONFIG.URL + CONFIG.apiPath + 'cantines/byId?id='+id;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this._http.get<any>(url, { headers: headers });
  }
  
  add(cantine: any): Observable<any[]> {
    const url2 = CONFIG.URL + CONFIG.apiPath + 'cantines/add?jours=' + cantine.jour + '&menu=' + cantine.menu;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this._http.post<any>(url2, ({
      cantines: cantine.cantines, description:cantine.description
    } || null), { headers: headers });

  }

  addMenu(menu:any){
    const url2 = CONFIG.URL + CONFIG.apiPath + 'menu/addMenu';
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this._http.post<any>(url2,menu, { headers: headers });
  }

  deleteMenu(menu:any){
    const url2 = CONFIG.URL + CONFIG.apiPath + 'menu/delete?id='+menu;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this._http.delete<any>(url2, { headers: headers });
  }

  deleteCantine(cantine:any){
    const url2 = CONFIG.URL + CONFIG.apiPath + 'cantines/delete?id='+cantine;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this._http.delete<any>(url2, { headers: headers });
  }

  updateCantine(cantine:any){
    const url2 = CONFIG.URL + CONFIG.apiPath + 'cantines/updateCantines?jours=' + cantine.jour + '&menu=' + cantine.menu;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this._http.put<any>(url2,cantine, { headers: headers });
  }

  updateMenu(menu:any){
    const url2 = CONFIG.URL + CONFIG.apiPath + 'menu/updateMenu';
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this._http.put<any>(url2,menu, { headers: headers });
  }
} 