import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { routerTransition } from '../../../../router.animations';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '../../user';
import { UtilisateursService } from '../../gestion-utilisateurs.service';
import { Router } from "@angular/router";
import { RolesService } from "../../../gestion-roles/gestion-roles.service";
import swal from 'sweetalert2';

@Component({
    selector: 'app-newuser',
    templateUrl: './add-new-user.component.html',
    styleUrls: ['./add-new-user.component.scss'],
    animations: [routerTransition()]
})
export class AddNewUserComponent implements OnInit {

    loginform: FormGroup;
    t = "";
    private list = [];
    private langues = [
        { id: 2, name: 'Français' },
        { id: 3, name: 'Anglais' },
    ];

    constructor(public router: Router, private userService: UtilisateursService, private formBuilder: FormBuilder, private rolesService: RolesService) {
        this.loginform = this.formBuilder.group({
            'username': ['', [Validators.maxLength(20)]],
            'select': ['', Validators.required],
            'nom': ['', [Validators.required]],
            'prenom': ['', [Validators.required]],
            //'photo': [''],
            'tel': ['', Validators.required],
            'email': ['', [Validators.required, Validators.email]],
            'password': ['', [Validators.required]]
            // ,'photo' : ''
        });
    }
    // [a-zA-Z]

    ngOnInit() {
        this.rolesService.getAllAuthorities()
            .subscribe((authorities) => {
                this.list = authorities;
            }, error => {
                console.log(error);
            });
    }

    addUser() {
        this.userService.addUser(this.loginform.controls['email'].value, this.loginform.controls['password'].value, this.loginform.controls['nom'].value,
            this.loginform.controls['prenom'].value, this.loginform.controls['email'].value, this.loginform.controls['tel'].value, this.loginform.controls['select'].value)
            .subscribe(
                (res) => {
                    console.log(res['response']);
                    if (res['response'] == true) {
                        this.router.navigate(['/gestion-utilisateurs']);
                        swal({
                            position: 'center',
                            title: 'L`ajout est effectué avec succès',
                           // showConfirmButton: false,
                           confirmButtonText: 'OK!',

                            timer: 1500
                        })
                    }
                },
                error => {
                    alert(error);
                });

    }

    makePass() {
        //this.t = Math.random().toString(36).slice(-8) + Math.random().toString(36).slice(2);
        var possible = "012#%&+@3456789#%&+@ABCDEFGH#%&+@IJKLMNOPQRST#%&+@0123456#%&+@789UVWXYZabcdefghijklmnopqrs#%&+@tuvwxyz012#%&+@3456789";
        this.t = "";
        for (var i = 0; i < 11; i++) {
            this.t += possible.charAt(Math.floor(Math.random() * possible.length));
        }
    }





}


