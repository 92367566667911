import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/login/login/login.service';
import { GestionCantinesService } from './../../layout/gestion-cantines/gestion-cantines.service';
import * as decode from 'jwt-decode';
@Component({
  selector: 'app-cantine-prent',
  templateUrl: './cantine-prent.component.html',
  styleUrls: ['./cantine-prent.component.scss']
})
export class CantinePrentComponent implements OnInit {
  cantineJour: any;
  loading :boolean =false ;
  user:any;
  username:any;
  constructor(private cantinesService: GestionCantinesService,
    private loginService: LoginService,
    public router: Router) { }

  ngOnInit() {
    this.getAllCantineJour();
    this.getbyusername();
  }

  getbyusername(){
    this.loading=true ;
    var decoded = decode(localStorage.getItem("token"));
    this.username = decoded.sub;
    this.loginService.getUserbyusername(this.username).subscribe((res=>
      {
        this.user = res;
                  if (this.user.profil == "Enseignant") {
                    this.router.navigate(["./mon-emplois"]);
                  } else if (this.user.profil == "Directeur") {
                    this.router.navigate(["DashboardDirecteur"]);
                  }
      }));
      this.loading=false;
  }
  getAllCantineJour() {
    this.cantinesService.getAllCantineJour().subscribe((data) => {
      this.cantineJour = data;
    }, error => {
      console.log(error);
    });
  }

}
